import { useState } from "react";
import { Link } from "react-router-dom";
import AddUser from "../../../SVG/AddUser";
import UserList from "./UserList";

export default function Users() {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="max-w-screen-2xl mx-auto px-1.2rem lg:px-2rem w-full h-full">
      <div className="my-5 lg:flex items-center justify-between pb-4">
        <h1 className="text-4xl font-semibold dark:text-gray-200">Users</h1>

        <div className="flex flex-col-reverse sm:flex-row sm:items-center -mt-[2.5rem] sm:mt-0">
          <input
            type="text"
            name="Team Search"
            placeholder="Search Users..."
            value={searchTerm}
            onChange={({ target }) => setSearchTerm(target.value)}
            className="input input-bordered w-full rounded mr-4 sm:max-w-[400px] max-h-[2.5rem] dark:bg-slate-700 dark:text-gray-200 border-dark"
          />

          <Link
            to="/dashboard/users/new"
            className="btn btn-primary rounded text-white flex p-2 px-4 mb-4 sm:mb-0 w-fit ml-auto max-h-[2.5rem] min-h-0"
          >
            <span className="mr-2">Add User</span>
            <AddUser />
          </Link>
        </div>
      </div>

      <UserList searchTerm={searchTerm} />
    </div>
  );
}

import React from "react";

const List = ({ height = 18, width = 18, color = "currentColor" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 12" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M16 12H4V10H16V12ZM2 12H0V10H2V12ZM16 7H4V5H16V7ZM2 7H0V5H2V7ZM16 2H4.023V0H16V2ZM2 2H0V0H2V2Z" />
    </svg>
  );
};

export default List;

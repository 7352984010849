import React from "react";

const Eye = ({ width = 21, height = 15, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.625 7.5C13.625 8.3288 13.2958 9.12366 12.7097 9.70971C12.1237 10.2958 11.3288 10.625 10.5 10.625C9.6712 10.625 8.87634 10.2958 8.29029 9.70971C7.70424 9.12366 7.375 8.3288 7.375 7.5C7.375 6.6712 7.70424 5.87634 8.29029 5.29029C8.87634 4.70424 9.6712 4.375 10.5 4.375C11.3288 4.375 12.1237 4.70424 12.7097 5.29029C13.2958 5.87634 13.625 6.6712 13.625 7.5Z"
        fill={color}
      />
      <path
        d="M0.5 7.5C0.5 7.5 4.25 0.625 10.5 0.625C16.75 0.625 20.5 7.5 20.5 7.5C20.5 7.5 16.75 14.375 10.5 14.375C4.25 14.375 0.5 7.5 0.5 7.5ZM10.5 11.875C11.6603 11.875 12.7731 11.4141 13.5936 10.5936C14.4141 9.77312 14.875 8.66032 14.875 7.5C14.875 6.33968 14.4141 5.22688 13.5936 4.40641C12.7731 3.58594 11.6603 3.125 10.5 3.125C9.33968 3.125 8.22688 3.58594 7.40641 4.40641C6.58594 5.22688 6.125 6.33968 6.125 7.5C6.125 8.66032 6.58594 9.77312 7.40641 10.5936C8.22688 11.4141 9.33968 11.875 10.5 11.875Z"
        fill={color}
      />
    </svg>
  );
};

export default Eye;

import React from "react";

const Play = ({ width = 12, height = 14, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0V14L11.6667 7L0 0Z" fill={color} />
    </svg>
  );
};

export default Play;

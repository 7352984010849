import { combineReducers, configureStore } from "@reduxjs/toolkit";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import thunk from "redux-thunk";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

import allProjectsReducer from "../slices/allProjects/allProjectsSlice";
import cachedProjectsReducer from "../slices/cachedProjects/cachedProjects";
import { serverApi } from "../slices/api/apiSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  stateReconciler: hardSet,
  blacklist: [serverApi.reducerPath],
};

const reducers = {
  allProjects: allProjectsReducer,
  cachedProjects: cachedProjectsReducer,
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: { [serverApi.reducerPath]: serverApi.reducer, persistedReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk, serverApi.middleware),
});

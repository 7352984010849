import React from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useTheme } from '../../../hooks/useTheme'

import ResetPassword from './ResetPassword'

const Settings = () => {
  const { user } = useAuth()

  const { toggleTheme } = useTheme()

  return (
    <div className="max-w-screen-2xl mx-auto px-1.2rem lg:px-2rem w-full h-full">
      <div className="my-5 flex items-center justify-between pb-2">
        <h1 className="text-4xl font-semibold dark:text-gray-200">Settings</h1>
      </div>

      <div className="max-w-[34rem] mx-auto">
        <div className="flex flex-wrap items-end  mx-auto dark:text-gray-200">
          <h3 className="text-xl mr-4">Email:</h3>
          <p className="text-xl">{user.email}</p>
        </div>

        <div className="flex flex-wrap items-center mx-auto dark:text-gray-200 mt-2">
          <h3 className="text-xl mr-4">Theme:</h3>

          <button onClick={toggleTheme} className="white-btn border-normal p-1 rounded-md">
            {/* Moon */}
            <svg
              className="w-7 h-7 hidden dark:block"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
            {/* Sun */}
            <svg
              className="w-7 h-7 dark:hidden"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </button>
        </div>

        <div className="my-2rem">
          <ResetPassword />
        </div>
      </div>
    </div>
  )
}

export default Settings

import React from 'react'

const Completed = ({ height = 20, color = 'currentColor' }) => {
  return (
    <svg
      height={height}
      width={height}
      className="bg-white rounded-full border-[1px]"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20.0004C4.48 19.9944 0.006 15.5204 0 10.0004V9.80039C0.11 4.30539 4.635 -0.0716126 10.13 0.000387396C15.627 0.0743874 20.034 4.56939 19.998 10.0654C19.962 15.5624 15.497 20.0004 10 20.0004ZM5.41 9.59039L4 11.0004L8 15.0004L16 7.00039L14.59 5.58039L8 12.1704L5.41 9.59039Z"
        fill={color}
      />
    </svg>
  )
}

export default Completed

import axios from '../axios'
import React, { useContext, useState } from 'react'

export const LOCAL_STORAGE_TOKEN_KEY = 'dashboard-token'

const AuthContext = React.createContext(null)

export function useAuth() {
  return useContext(AuthContext)
}

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null)

  function getToken() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
  }

  function setToken(token) {
    return localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
  }

  function removeToken() {
    return localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
  }

  function logout() {
    setUser(null)
    removeToken()
  }

  function isUserAdmin() {
    if (!user) return false
    return user.role === 'Admin' || user.role === 'SuperAdmin'
  }

  async function createUser(email, role, projects) {
    try {
      const response = await axios.post(
        '/admin/users/new',
        { email, role, projects },
        { headers: { Authorization: `JWT ${getToken()}` } }
      )

      return response.data
    } catch (err) {
      console.error(err)
      return err.response?.data
    }
  }

  async function login(email, password) {
    try {
      const response = await axios.post('/auth/login', { email, password })

      const { user, token } = response.data

      if (user && token) {
        setToken(token)
        setUser(user)
      }

      return response.data
    } catch (err) {
      console.error(err)
      return err.response?.data
    }
  }

  async function requestResetEmail(email) {
    try {
      const response = await axios.post('/auth/password/resetRequest', { email })
      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function resetPassword(token, password) {
    try {
      const response = await axios.patch(`/auth/password/reset/${token}`, { password })
      removeToken()
      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function updateEmail(email) {
    try {
      const response = await axios.put(
        '/users/update/email',
        { email },
        { headers: { Authorization: `JWT ${getToken()}` } }
      )

      const { updatedUser, token } = response.data
      if (updatedUser && token) {
        setToken(token)
        setUser(updatedUser)
      }

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function updatePassword(password) {
    try {
      const response = await axios.put(
        '/users/update/password',
        { password },
        { headers: { Authorization: `JWT ${getToken()}` } }
      )

      const { updatedUser, token } = response.data

      if (updatedUser && token) {
        setToken(token)
        setUser(updatedUser)
      }

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function verifyToken(token = getToken()) {
    try {
      if (!token) throw Error('No token found in local storage')

      const response = await axios.get('/auth/verify', {
        headers: { Authorization: `JWT ${token}` },
      })

      const { user } = response.data

      if (user) setUser(user)
      else logout()

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function joinProject(projectId, userId = user._id) {
    try {
      const response = await axios.put(
        '/admin/projects/add',
        { userId, projectId },
        { headers: { Authorization: `JWT ${getToken()}` } }
      )

      if (userId === user._id) {
        const { updatedUser, token } = response.data
        setToken(token)
        setUser(updatedUser)
      }

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function leaveProject(projectId, userId = user._id) {
    try {
      const response = await axios.put(
        '/admin/projects/remove',
        { userId, projectId },
        { headers: { Authorization: `JWT ${getToken()}` } }
      )

      if (userId === user._id) {
        const { updatedUser, token } = response.data
        setToken(token)
        setUser(updatedUser)
      }

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function postsTriageIssue(issueData) {
    try {
      const response = await axios.post('/dashboard/issue/create', issueData, {
        headers: { Authorization: `JWT ${getToken()}` },
      })

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function postIssueComment(issueId, commentBody) {
    try {
      const response = await axios.post(
        `/dashboard/issue/${issueId}/comment`,
        { commentBody },
        { headers: { Authorization: `JWT ${getToken()}` } }
      )

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  async function updatePreferences(property, value) {
    try {
      const response = await axios.put(
        '/users/update/preferences',
        { property, value },
        { headers: { Authorization: `JWT ${getToken()}` } }
      )

      if (response.data) {
        const { updatedUser, token } = response.data
        setToken(token)
        setUser(updatedUser)
      }

      return response.data
    } catch (err) {
      console.log(err)
      return err.response?.data
    }
  }

  const value = {
    user,
    createUser,
    login,
    logout,
    requestResetEmail,
    resetPassword,
    verifyToken,
    token: getToken(),
    isAdmin: isUserAdmin(),
    joinProject,
    leaveProject,
    postsTriageIssue,
    postIssueComment,
    updatePreferences,
    updateEmail,
    updatePassword,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

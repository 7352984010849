import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { useGetAllProjectsQuery } from "../../redux/slices/api/apiSlice";
import Magnify from "../../SVG/Magnify";
import Minus from "../../SVG/Minus";
import Plus from "../../SVG/Plus";

const UserProjects = () => {
  const { user } = useAuth();
  const location = useLocation();

  const [collapsedTeams, setCollapsedTeams] = useState([]);
  const [projectSearchTerm, setProjectSearchTerm] = useState("");

  const { allProjects } = useSelector((state) => state.persistedReducer.allProjects);
  useGetAllProjectsQuery();

  // Filter user linear projects
  const userProjectIdMap = new Map();
  user.projects.forEach((id) => userProjectIdMap.set(id, true));
  const userProjects = allProjects.filter((project) => userProjectIdMap.get(project.id));

  // Categorize Projects by Team
  const projectsByTeam = new Map();
  userProjects.forEach((project) => {
    project.teams.map((team) =>
      projectsByTeam.set(team, [
        ...(projectsByTeam.get(team) || []),
        { ...project, route: `/dashboard/projects/${project.id}` },
      ])
    );
  });

  const addCollapsedTeam = (team) => {
    setCollapsedTeams((prev) => [...prev, team]);
  };

  const removeCollapsedteam = (team) => {
    setCollapsedTeams((prev) => prev.filter((t) => t !== team));
  };

  const renderLink = ({ name, route }, teamName) => {
    return (
      <div className="w-full flex flex-col" key={route + "_" + teamName}>
        <NavLink
          to={route}
          className={`
            flex 
            items-center 
            py-4 
            pr-4 
            pl-8
            hover:bg-gray-200 
            active:bg-gray-300 
            dark:hover:bg-gray-800 
            dark:active:bg-gray-900 
            dark:text-gray-300 
            ${location.pathname === route && "!bg-primary !text-white"}
          `}
        >
          <span className="text-[0.9rem]">{name}</span>
        </NavLink>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col overflow-y-auto border-b border-dark">
      {/* Search */}
      <div className="sticky top-0 bg-white z-20 dark:bg-slate-600 flex items-center pl-4 border-b border-dark">
        <span className="z-10 text-gray-500 dark:text-gray-400 pointer-events-none">
          <Magnify height={16} width={16} />
        </span>
        <input
          type="text"
          value={projectSearchTerm}
          onChange={({ target }) => setProjectSearchTerm(target.value)}
          className="
            py-6 
            border-none 
            rounded-none 
            w-[112%] 
            max-h-[2.5rem] 
            -ml-2rem pl-[2.6rem] 
            placeholder:text-gray-400 
            bg-inherit 
            dark:text-gray-200 
            transition-none
          "
          placeholder={"Search Projects..."}
        />
      </div>

      <ul className="overflow-y-auto relative thin-scrollbar">
        {/* Project List */}
        {[...projectsByTeam.entries()].map((team) => {
          const teamName = team[0];
          const links = team[1];

          const matchingSearchTeams = links.filter(
            ({ name, teams }) =>
              name.toLowerCase().includes(projectSearchTerm.toLowerCase()) ||
              teams.some((t) => t.toLowerCase().includes(projectSearchTerm.toLowerCase()))
          );

          const isCollapsed = collapsedTeams.includes(teamName);

          if (!matchingSearchTeams.length) return <></>;

          return (
            <li className="w-full flex flex-col border-b border-dark" key={teamName}>
              <div className=" z-10 bg-white dark:bg-slate-700 ">
                <button
                  className="flex px-4 justify-between py-3 w-full dark:text-gray-200"
                  onClick={() => (isCollapsed ? removeCollapsedteam(teamName) : addCollapsedTeam(teamName))}
                >
                  <p className="font-[500] text-[1rem] text-left">{teamName}</p>
                  {isCollapsed ? <Plus /> : <Minus />}
                </button>
              </div>

              {!isCollapsed && matchingSearchTeams.map((link) => renderLink(link, teamName))}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default UserProjects;

import React from "react";
import { useParams } from "react-router-dom";

import WithToken from "../WithToken";

const PasswordSet = () => {
  const { token } = useParams();

  return (
    <main className="dark:bg-slate-800">
      <div className="w-full max-w-lg mx-auto pt-4rem">
        <h1 className="text-center font-bold text-4xl dark:text-gray-200">Set A Password</h1>

        <WithToken token={token} />
      </div>
    </main>
  );
};

export default PasswordSet;

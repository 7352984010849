import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import axios from '../../../axios'
import { useAuth } from '../../../contexts/AuthContext'
import Projects from '../Projects'
import Modal from '../../Shared/Modal'

const toastOptions = {
  position: 'top-right',
  autoClose: 1500,
}

export default function User() {
  const navigate = useNavigate()
  const roleSelect = useRef(null)
  const { token, user } = useAuth()

  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false)

  const [userData, setUserData] = useState({
    _id: '',
    role: '',
    projects: [],
    email: '',
    isActive: false,
    updatedAt: '',
    createdAt: '',
  })
  const [selectedRole, setSelectedRole] = useState('Admin')

  const urlSplit = window.location.href.split('/')
  const userEmail = urlSplit[urlSplit.length - 1]

  const deleteUser = async () => {
    try {
      const res = await axios.delete(`/admin/users/${userData.email}`, { headers: { Authorization: `JWT ${token}` } })
      console.log(res)
      toast.success(res.data.message, toastOptions)
      navigate('/dashboard/users', { replace: true })
    } catch (e) {
      toast.error(e.response?.data?.message, toastOptions)
      console.log(e)
    }
  }

  const changeRole = async () => {
    try {
      const res = await axios.put(
        `/admin/users/${userData.email}`,
        { role: selectedRole },
        { headers: { Authorization: `JWT ${token}` } }
      )
      setUserData(res.data.updatedUser)
      toast.success(res.data.message, toastOptions)
    } catch (e) {
      toast.error(e.response?.data?.message, toastOptions)
      console.log(e)
    }
  }

  useEffect(() => {
    ;(async () => {
      const res = await axios.get(`/admin/users/${userEmail}`, {
        headers: { Authorization: `JWT ${token}` },
      })
      setUserData(res.data)
      setSelectedRole(res.data.role)
    })()
  }, [token, userEmail])

  if (!userData._id) return <></>

  return (
    <>
      <div className="max-w-screen-2xl mx-auto w-full h-full dark:text-gray-200">
        <div className="px-1.2rem lg:px-2rem pb-4">
          <div className="my-5 flex justify-between items-end">
            <h1 className="text-4xl font-semibold">{userEmail}</h1>

            {user.role === 'SuperAdmin' && userData.role !== 'SuperAdmin' && (
              <button
                onClick={() => setConfirmDeleteUser(true)}
                type="button"
                className="white-btn py-2 hover:bg-red-400 dark:hover:bg-red-800"
              >
                Delete User
              </button>
            )}
          </div>

          {user.role === 'SuperAdmin' && (
            <div className="flex flex-col w-fit min-w-[205px]">
              <h2 className="text-2xl border-b border-normal mb-2 font-bold">Role</h2>

              <div className="flex items-stretch">
                <select
                  className="select rounded transition-all dark:bg-slate-600 border border-dark"
                  ref={roleSelect}
                  value={selectedRole}
                  onChange={({ target }) => setSelectedRole(target.value)}
                >
                  <option value="SuperAdmin">Super Admin</option>
                  <option value="Admin">Admin</option>
                  <option value="Client">Client</option>
                </select>

                {selectedRole !== userData.role && (
                  <button className="btn-primary text-white ml-2 rounded px-4" onClick={changeRole}>
                    Save
                  </button>
                )}
              </div>
            </div>
          )}
        </div>

        <Projects userData={userData} setUserData={setUserData} />
      </div>

      {confirmDeleteUser && (
        <Modal closeModal={() => setConfirmDeleteUser(false)} showCloseButton={false}>
          <div className="p-8 text-center">
            <p className="dark:text-white">
              Are you sure you want to delete <br />
              {userData.email}?
            </p>
            <div className="flex items-center justify-between mt-4">
              <button onClick={() => setConfirmDeleteUser(false)} className="white-btn py-2" type="button">
                Cancel
              </button>
              <button onClick={deleteUser} className="ml-8 white-btn py-2 !bg-red-400 dark:!bg-red-800" type="button">
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  isLoading: true,
  cachedProjects: {},
};

export const cachedProjects = createSlice({
  name: "cachedProjects",
  initialState: initialState,
  reducers: {
    setCachedProjectsLoading(state) {
      state.isLoading = true;
    },

    addCachedProject(state, action) {
      const project = action.payload;

      const issueDates = project.issues?.nodes.map((node) =>
        node.dueDate ? dayjs(node.dueDate) : dayjs(node.createdAt)
      );

      const allProjectDates = [
        dayjs(project.startDate),
        ...issueDates,
        dayjs(project.targetDate),
        dayjs().subtract(7, "day"), // minimum 1 week before today
        dayjs().add(7, "day"), // max 1 week ahead of today
      ].filter((date) => date.isValid());

      const startDate = dayjs.min(allProjectDates);
      const endDate = dayjs.max(allProjectDates);

      const dayDiff = endDate.diff(startDate, "day") + 1; // +1 to include endDate

      state.cachedProjects[project.id] = {
        ...project,
        dayDiff,
        fetchedAt: dayjs().toDate().toString(),
        timelineStartDate: startDate.toDate().toString(),
      };
      state.isLoading = false;
    },

    updateProjectIssue(state, action) {
      const { issue, projectId } = action.payload;
      const project = state.cachedProjects[projectId];
      if (!project) return;

      const issues = project.issues.nodes.map((i) => (i.id === issue.id ? issue : i));
      const updatedProject = { ...project, issues: { nodes: issues } };
      state.cachedProjects[projectId] = updatedProject;
    },

    addProjectIssue(state, action) {
      const { issue, projectId } = action.payload;
      const project = state.cachedProjects[projectId];
      if (!project) return;

      const issues = [...project.issues.nodes, issue];
      const updatedProject = { ...project, issues: { nodes: issues } };
      state.cachedProjects[projectId] = updatedProject;
    },
  },
});

export const { setCachedProjectsLoading, addCachedProject, updateProjectIssue, addProjectIssue } =
  cachedProjects.actions;

export default cachedProjects.reducer;

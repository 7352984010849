import React from "react";

const ExitRight = ({ width = 16, height = 14, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00002 14L4.00002 12L2.00002 12L2.00003 2L4.00003 2L4.00003 -9.9662e-07L2.60195e-05 -1.34631e-06L2.47955e-05 14L4.00002 14ZM4.00003 6L4.00003 8L11.6 8L7.30003 12.3L8.70002 13.7L15.4 7L8.70003 0.299999L7.30003 1.7L11.6 6L4.00003 6Z"
        fill={color}
      />
    </svg>
  );
};

export default ExitRight;

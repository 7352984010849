export default function Loader() {
  return (
    <img
      className='animate-spin'
      src='/logo192.png'
      alt='Loading Icon'
      width={96}
    />
  );
}

import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import ElectriqLogo from '../../SVG/ElectriqLogo'

export default function Navbar({ setSidebarOpen }) {
  const { user, token } = useAuth()

  return (
    <nav
      className={`bg-white border-b border-dark sticky top-0 z-[999] h-[85px] w-full flex items-center dark:bg-slate-700 
      ${user && token && 'lg:w-[260px] lg:border-r'}`}
    >
      <div className="navbar py-0 px-1.2rem max-w-screen-2xl mx-auto">
        {/* Mobile Nav */}
        <div className="navbar-start w-full">
          {user && (
            <button
              className="lg:hidden mr-2 sm:mr-4 dark:text-gray-200"
              onClick={() => setSidebarOpen((previous) => !previous)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
              </svg>
            </button>
          )}

          {/* Logo */}
          <Link to={user ? '/dashboard' : '/'} className={`${user && 'mx-auto pr-[2rem]'} lg:px-0 lg:m-0`}>
            <span className="block w-[170px] lg:w-[190px] dark:text-white">
              <ElectriqLogo />
            </span>
          </Link>
        </div>

        {!user && (
          <div className="text-black flex items-center justify-center">
            <Link to="/login" className="white-btn border border-normal px-5 lg:px-8 py-2">
              Login
            </Link>
          </div>
        )}
      </div>
    </nav>
  )
}

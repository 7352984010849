import React from 'react'

import ProjectCard from './ProjectCard'

const ProjectsGrid = ({ projects, joinedProjectsMap, joinProject, leaveProject, isEditing }) => {
  return (
    <div
      className="
        grid
        grid-cols-1
        sm:grid-cols-2
        xl:grid-cols-3
        gap-6
        pb-2.4rem"
    >
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          hasJoinedProject={joinedProjectsMap.get(project.id)}
          joinProject={joinProject}
          leaveProject={leaveProject}
          isEditing={isEditing}
        />
      ))}
    </div>
  )
}

export default ProjectsGrid

import React from 'react'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
dayjs.extend(minMax)

const ItemBar = ({ item, isFirstBar = false, ...ItemBarProps }) => {
  const {
    CollapsedRowHeight,
    RowHeight,
    dateWidth,
    itemChildrenMap,
    getItemsDateRange,
    getDayDiff,
    getNumDaysShown,
    isItemParentCollapsed,
    scrollToDate,
    handleRowMouseOver,
  } = ItemBarProps

  let { startDate, endDate, startLabel, endLabel } = item

  const daysBetween = getDayDiff(startDate, endDate)

  const { firstDate } = getItemsDateRange
  const offsetDaysStart = getDayDiff(firstDate, endDate)

  const minWidth = dateWidth / 3
  const barWidth = daysBetween * dateWidth
  // Used to push items with 0 daysBetween behind today bar
  const startsAndEndsToday = dayjs(startDate).isSame(endDate, 'day') && dayjs(endDate).isSame(dayjs(), 'day')

  const { isParentCollapsed } = isItemParentCollapsed(item)
  const height = isParentCollapsed ? CollapsedRowHeight : RowHeight
  const barMarginLeft = offsetDaysStart * dateWidth - (barWidth || (startsAndEndsToday ? minWidth : 0)) + dateWidth / 2

  return (
    <>
      <div
        data-item-id={item.id}
        className="gantt-bar inline-flex items-center select-none transition-[height]"
        onMouseEnter={() => handleRowMouseOver(item.id)}
        onMouseLeave={() => handleRowMouseOver(-1)}
        onClick={() => scrollToDate(item.endDate || dayjs().format('YYYY-MM-DD'), false)}
        style={{ width: `${dateWidth * getNumDaysShown()}px`, height: `${height}px` }}
      >
        <div style={{ marginRight: `${dateWidth * 7}px` }}>
          <div
            className={`dark:bg-slate-800 rounded text-[.65rem] flex items-center justify-between dark:text-slate-400 relative transition-[height]
              ${item.barLabel && barWidth < 100 && `tooltip ${isFirstBar && 'tooltip-bottom'}`}`}
            data-tip={item.barLabel}
            style={{
              width: `${barWidth || minWidth}px`,
              height: `${height / 2}px`,
              marginLeft: `${barMarginLeft}px`,
              background: item.barColor,
            }}
          >
            {item.barLabel && barWidth > 100 && !isParentCollapsed && (
              <span
                className="truncate text-white pl-1 sticky left-0 animate-fade-in"
                style={{ color: item.barLabelColor }}
              >
                {item.barLabel}
              </span>
            )}
            {startLabel && !isParentCollapsed && (
              <span className="absolute right-[calc(100%+.3rem)] min-w-max z-[15]">{startLabel}</span>
            )}
            {endLabel && !isParentCollapsed && (
              <span className="absolute left-[calc(100%+.3rem)] min-w-max z-[15]">{endLabel}</span>
            )}
          </div>
        </div>
      </div>

      {itemChildrenMap.get(item.id)?.map((child) => (
        <ItemBar key={child.id + '_bar'} item={child} {...ItemBarProps} />
      ))}
    </>
  )
}

export default ItemBar

import React from 'react'

const Collapse = ({ height = 16, width = 16, color = 'currentColor' }) => {
  return (
    <svg width={width} height={height} fill={color} viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg">
      <path d="M26,20H6a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V22A2,2,0,0,0,26,20Zm0,6H6V22H26Z" />
      <polygon points="17 7.828 19.586 10.414 21 9 16 4 11 9 12.414 10.414 15 7.828 15 14 4 14 4 16 28 16 28 14 17 14 17 7.828" />
    </svg>
  )
}

export default Collapse

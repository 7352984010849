import { useState } from 'react'
import dayjs from 'dayjs'
import ScrollContainer from 'react-indiana-drag-scroll'

import Column from './Column'
import { Filters } from '..'
import Flag from '../../../../SVG/Flag'
import ListDown from '../../../../SVG/ListDown'

const SortOptions = [
  { field: 'completedAt', label: 'Completed At', icon: <ListDown /> },
  { field: 'priority', label: 'Priority', icon: <Flag /> },
  { field: 'dueDate', label: 'Due Date', icon: <ListDown /> },
]

export default function Kanban({ project }) {
  const [sorting, setSorting] = useState('completedAt')
  const [searchTerm, setSearchTerm] = useState('')

  const sortIssues = (type) => {
    // Filter by search term and state type
    let issues = project.issues.nodes.filter((issue) => {
      const newerThan45Days = dayjs(issue.completedAt || dayjs()).isAfter(dayjs().subtract(45, 'd'))

      const isSearched =
        issue.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        issue.parent?.title.toLowerCase().includes(searchTerm.toLowerCase())

      return (
        isSearched &&
        newerThan45Days &&
        (type === 'Backlog'
          ? issue.state.name === 'Backlog' || issue.state.name === 'Triage'
          : issue.state.name === type)
      )
    })

    switch (sorting) {
      case 'completedAt':
        issues.sort((issue1, issue2) =>
          dayjs(issue1.completedAt || issue1.dueDate).isBefore(dayjs(issue2.completedAt || issue2.dueDate)) ? 1 : -1
        )
        break
      case 'dueDate':
        const hasDueDate = []
        const noDueDate = []
        issues.map((issue) => (issue.dueDate ? hasDueDate.push(issue) : noDueDate.push(issue)))

        issues = [
          ...hasDueDate.sort((issue1, issue2) => (dayjs(issue1.dueDate).isBefore(dayjs(issue2.dueDate)) ? 1 : -1)),
          ...noDueDate,
        ]
        break
      case 'priority':
        const lowPriority = []
        const notLowPriority = []
        issues.map((issue) => (issue.priority ? notLowPriority.push(issue) : lowPriority.push(issue)))

        issues = [...notLowPriority.sort((issue1, issue2) => issue1.priority - issue2.priority), ...lowPriority]
        break
      default:
        break
    }

    return issues
  }

  return (
    <div className="max-w-screen-2xl mx-auto">
      <div>
        <div className="flex justify-between items-center border-b border-normal mb-2 px-2">
          <span className="font-bold text-1.2rem dark:text-gray-200">Sort By</span>
          <span className="font-bold text-1.2rem dark:text-gray-200">Search</span>
        </div>

        <div className="flex items-center justify-between flex-wrap">
          {/* Sorting Options */}
          <ul className="flex items-center">
            {SortOptions.map(({ field, label, icon }) => (
              <li key={field} className="mr-2 mb-2 lg:mb-0">
                <button
                  className={`white-btn border-none px-4 py-2 rounded-full transition-all flex items-center dark:text-white
                ${sorting === field ? 'shadow-[inset_0_0_0_2px_#888]' : ''}`}
                  onClick={() => setSorting(field)}
                >
                  {label} <span className="ml-2 mt-[2px] text-gray-500 dark:text-gray-300">{icon}</span>
                </button>
              </li>
            ))}
          </ul>

          {/* Search Input */}
          <div>
            <input
              type="text"
              className="input input-bordered w-full h-auto py-1 rounded dark:bg-slate-700 dark:text-gray-200 border-normal mb-2"
              placeholder="Search tasks"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <ScrollContainer
        hideScrollbars={false}
        className="flex pt-4 lg:px-3 -ml-1.2rem lg:-ml-2rem w-[calc(100%+2.4rem)] lg:w-[calc(100%+4rem)] h-full thin-scrollbar"
        ignoreElements=".prevent-drag-scroll"
      >
        {Filters.map((filter) => (
          <Column type={filter} issues={sortIssues(filter)} key={filter} />
        ))}
      </ScrollContainer>
    </div>
  )
}

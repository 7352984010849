import React from 'react'

const index = () => {
  return (
    <section className="max-w-screen-2xl mx-auto px-1.2rem lg:px-2rem w-full h-full dark:text-gray-200">
      <div className="my-5 pb-2 border-b border-normal">
        <h1 className="text-4xl font-semibold">Learn</h1>
      </div>

      <article className="max-w-3xl mx-auto">
        <div className="py-6">
          <h2 className="text-3xl">Sidebar</h2>

          <ul className="list-none pl-6">
            <li className="py-4">
              <h3 className="text-2xl font-light">All Projects</h3>
              <p className="text-gray-600 dark:text-gray-400 text-lg">
                Clicking this shows you all the projects that you have access to, as well as a timeline showing how far
                along the project pipeline each project currently is.
              </p>
            </li>
            <li className="py-4">
              <h3 className="text-2xl font-light">Your Projects</h3>
              <p className="text-gray-600 dark:text-gray-400 text-lg">
                Next in the sidebar, you will see a list of your projects for quick access. Projects can be part of the
                development team or design team or both if there are overlapping requirements.
              </p>
            </li>
            <li className="py-4">
              <h3 className="text-2xl font-light">Learn</h3>
              <p className="text-gray-600 dark:text-gray-400 text-lg">
                Towards the bottom is the Learn page. In there you will find information like this, about how to use the
                Electriq Dashboard.
              </p>
            </li>
            <li className="py-4">
              <h3 className="text-2xl font-light">Settings</h3>
              <p className="text-gray-600 dark:text-gray-400 text-lg">
                Below that is the Settings page. In the settings, you can change your password and toggle the theme of
                the UI.
              </p>
            </li>
            <li className="py-4">
              <h3 className="text-2xl font-light">Logout</h3>
              <p className="text-gray-600 dark:text-gray-400 text-lg">
                At the very bottom is the logout button which securely logs you out of the app.
              </p>
            </li>
          </ul>
        </div>

        <div className="py-6">
          <h2 className="text-3xl">Projects</h2>

          <p className="text-gray-600 dark:text-gray-400 text-lg">
            Clicking on one of the project tabs in the sidebar or a project from the All Projects page will take you to
            the individual project page.
          </p>

          <div className="mb-4">
            <ul className="list-none pl-6">
              <li className="py-4">
                <h3 className="text-2xl font-light">Kanban</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  Tasks are categorized by their progress in the project pipeline. Tasks make their way from the left
                  side to the right and each task shows all relevant information such as due date, priority, labels, and
                  assignee.
                </p>
              </li>
              <li className="py-4">
                <h3 className="text-2xl font-light">Timeline</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  A high-level view of tasks that shows starting dates, ending dates, progress, and subtasks. Zoom in to
                  see day-to-day tasks or zoom out to view monthly updates. Collapse subtasks for a more compact view or
                  expand them to view task details.
                </p>
              </li>
              <li className="py-4">
                <h3 className="text-2xl font-light">Calendar</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  View due dates for tasks in a monthly calendar format. Filter out certain types of tasks by clicking
                  the task labels above the calendar. For tasks without a due date, select the “TBD Tasks” button.
                </p>
              </li>
            </ul>
          </div>

          <div>
            <p className="text-gray-600 dark:text-gray-400 text-lg">
              The ability to <strong className="text-gray-700 dark:text-gray-300">comment on tasks</strong> is available
              on the Kanban and Calendar. Simply click “Leave Comment” on a task card and send us your comment.
            </p>

            <p className="text-gray-600 dark:text-gray-400 pt-2">Note: Projects are updated every 30 minutes</p>
          </div>
        </div>

        <div className="py-6">
          <h2 className="text-3xl">Task Requests</h2>

          <div className="mb-4">
            <ul className="list-none pl-6">
              <li className="py-4">
                <h3 className="text-xl font-light">Company Name</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  The name of the company of which the task belongs
                </p>
              </li>
              <li className="py-4">
                <h3 className="text-xl font-light">Issue Title</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">A unique title that describes to task</p>
              </li>
              <li className="py-4">
                <h3 className="text-xl font-light">Details</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">A description about what the task entails</p>
              </li>
              <li className="py-4">
                <h3 className="text-xl font-light">Priority</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  Can be either No Priority, Urgernt, High, Medium, or Low
                </p>
              </li>
              <li className="py-4">
                <h3 className="text-xl font-light">Requested Completion Date</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">Due date for the task</p>
              </li>
              <li className="py-4">
                <h3 className="text-xl font-light">Resource Links</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  External files or websites to reference for the task
                </p>
              </li>
              <li className="py-4">
                <h3 className="text-xl font-light">Images</h3>
                <p className="text-gray-600 dark:text-gray-400 text-lg">
                  Drag and drop or click to select files to upload
                </p>
                <p className="text-gray-600 dark:text-gray-400 text-sm mt-2">
                  Note: The maximum total size of all images combined is 240kb. If more space is needed, consider
                  uploading images to a Google Drive folder and linking it in the Resource Links.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  )
}

export default index

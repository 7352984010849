import { useState } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth } from '../../../../contexts/AuthContext'
import { useUpdateProjectIssueMutation } from '../../../../redux/slices/api/apiSlice'

import CalendarIcon from '../../../../SVG/Calendar'
import ChildArrow from '../../../../SVG/ChildArrow'
import Checkmark from '../../../../SVG/Checkmark'

export default function IssueCard({ issue }) {
  const { user, postIssueComment } = useAuth()
  const { projectId } = useParams()

  const [showCommentInput, setShowCommentInput] = useState(false)
  const [issueComment, setIssueComment] = useState('')

  const [updateProjectIssue] = useUpdateProjectIssueMutation()

  const handleIssuePriorityChange = async (e) => {
    const priority = e.target.value
    updateProjectIssue({ issueId: issue.id, priority, projectId })
  }

  const handleIssueComment = async () => {
    if (!issueComment)
      return toast.error('Please enter a comment to send', {
        position: 'top-right',
        autoClose: 3000,
      })

    const commentBody = issueComment + `\n\nBy:\n${user.email}`

    const res = await postIssueComment(issue.id, commentBody)

    if (res?.success) {
      setIssueComment('')
      setShowCommentInput(false)

      toast.success(res?.message, {
        position: 'top-right',
        autoClose: 3000,
      })
    } else {
      toast.error(res?.message, {
        position: 'top-right',
        autoClose: 3000,
      })
    }
  }

  const renderLabels = () => {
    const labels = issue.labels.nodes

    return (
      <div className="flex flex-wrap items-center mb-3">
        <h5 className="mr-2">Labels:</h5>
        <ul className="flex flex-wrap">
          {labels.map((label) => (
            <li
              className="text-xs text-white rounded-full px-2 py-1 mr-1"
              style={{ backgroundColor: label.color }}
              key={label.name}
            >
              {label.name}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const renderComment = () => {
    return (
      <div className="prevent-drag-scroll min-w-full mb-1">
        <button
          type="button"
          onClick={() => setShowCommentInput(!showCommentInput)}
          className="flex items-center justify-between min-w-full"
        >
          <span className="hover:underline">Leave Comment</span>
          {showCommentInput && <span>×</span>}
        </button>

        {showCommentInput && (
          <div>
            <textarea
              className="textarea textarea-bordered dark:bg-slate-500 mt-1 min-w-full"
              value={issueComment}
              onChange={({ target }) => setIssueComment(target.value)}
              rows={4}
              required
              autoFocus
            ></textarea>
            <button
              type="submit"
              className="flex items-center white-btn mt-1 py-[.15rem] px-2 text-sm rounded"
              onClick={handleIssueComment}
            >
              Send
            </button>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="relative flex flex-col items-start group z-50 bg-inherit dark:text-gray-100">
      {/* Issue & Issue Parent Title */}
      {issue.parent && <h6 className="text-xs text-gray-400 dark:text-gray-400">{issue.parent.title}</h6>}
      <div className="flex items-start">
        <h4 className="font-medium pr-4 max-w-full break-words flex-1">
          <span className="text-gray-400 dark:text-gray-400 float-left">
            {issue.parent && <ChildArrow height={18} width={18} />}
          </span>
          {issue.title}
        </h4>
      </div>

      <div className="flex flex-col items-start w-full mt-6 text-xs font-medium text-gray-700 dark:text-gray-200">
        {/* Date */}
        {issue.completedAt && (
          <div className="flex items-center mr-4 mb-3">
            <span className="tooltip tooltip-right" data-tip="Completed">
              <Checkmark height={14} width={14} />
            </span>
            <span className="ml-1 leading-none tracking-wide dark:text-gray-200">
              {dayjs(issue.completedAt).format('MM/DD/YY')}
            </span>
          </div>
        )}
        <div className="flex items-center mr-4 mb-3">
          <span className="tooltip tooltip-right" data-tip="Due Date">
            <CalendarIcon height={14} width={14} />
          </span>
          <span className="ml-1 leading-none tracking-wide dark:text-gray-200">
            {issue.dueDate ? dayjs(issue.dueDate).format('MM/DD/YY') : 'TBD'}
          </span>
        </div>

        {/* Priority */}
        <div className="flex items-center mr-4 mb-3">
          <h5 className="mr-2">Priority:</h5>
          <span
            className={cx('flex items-center rounded-full border', {
              'text-yellow-700 bg-yellow-100 border-yellow-100': issue.priority === 4,
              'text-orange-700 bg-orange-100 border-orange-100': issue.priority === 3,
              'text-pink-800 bg-pink-100 border-pink-100': issue.priority === 2,
              'text-red-700 bg-red-100 border-red-300': issue.priority === 1,
              'text-blue-700 bg-blue-100 border-blue-100': issue.priority === 0,
            })}
          >
            {/* {renderPriorityText(issue.priority)} */}
            <select
              name="issue-priority"
              className="prevent-drag-scroll appearance-none bg-inherit text-xs font-semibold rounded-full text-center h-6 px-2 relative cursor-pointer"
              value={issue.priority}
              onChange={handleIssuePriorityChange}
            >
              <option value="1">Urgent</option>
              <option value="2">High</option>
              <option value="3">Medium</option>
              <option value="4">Low</option>
              <option value="0">No Priority</option>
            </select>
          </span>
        </div>

        {Boolean(issue.labels.nodes.length) && renderLabels()}

        {renderComment()}
      </div>

      {/* Image */}
      {issue.assignee && (
        <div className="tooltip tooltip-left ml-auto z-50 mr-2" data-tip={issue.assignee.name}>
          <img
            className={`w-6 h-6 mask mask-squircle block ${!issue.assignee.avatarUrl && 'dark:brightness-[70%]'}`}
            src={issue.assignee.avatarUrl || '/images/default-avatar.jpeg'}
            alt={issue.assignee.name}
          />
        </div>
      )}
    </div>
  )
}

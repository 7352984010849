import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center dark:bg-slate-800">
      <img
        src="/images/404-orb.png"
        height={300}
        width={600}
        alt="Page not found"
        className="max-w-screen block px-1.2rem rounded-[5%]"
      />

      <h1 className="mt-2rem text-4xl dark:text-gray-200">404 - Page Not Found</h1>

      <button onClick={() => navigate(-1)} className="mt-2rem text-lg dark:text-gray-200 white-btn">
        Go Back
      </button>
    </div>
  );
};

export default NotFound;

import { useState } from 'react'
import { Filters, FilterColors } from '..'
import Modal from '../../../Shared/Modal'
import IssueCard from './IssueCard'

export default function IssueFilter({ issues, hiddenFilters, setHiddenFilters, onTodayClick }) {
  const [showTBDModal, setShowTBDModal] = useState(false)

  const filterIssues = (type) => {
    return issues.nodes.filter((issue) => {
      if (type === 'Backlog') return (issue.state.name === 'Backlog' || issue.state.name === 'Triage') && issue.dueDate
      return issue.state.name === type && (issue.dueDate || issue.completedAt)
    })
  }

  const toggleFilter = (filter) => {
    hiddenFilters.includes(filter)
      ? setHiddenFilters(hiddenFilters.filter((f) => f !== filter))
      : setHiddenFilters([...hiddenFilters, filter])
  }

  const isIssueHidden = (issue) => {
    return hiddenFilters.includes('Backlog')
      ? [...hiddenFilters, 'Triage'].includes(issue.state.name)
      : hiddenFilters.includes(issue.state.name)
  }

  const TBDIssues = issues.nodes.filter(
    (issue) =>
      !issue.dueDate && !issue.completedAt && (Filters.includes(issue.state.name) || issue.state.name === 'Triage')
  )

  const numShownIssues = issues.nodes.filter((i) => (i.dueDate || i.completedAt) && !isIssueHidden(i)).length

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-2xl mx-auto xl:flex-row xl:items-end xl:justify-between space-y-2">
        {/* Filters */}
        <div className="flex flex-col">
          <div className="flex justify-between items-center border-b border-normal mb-2 px-2 dark:text-gray-200">
            <span className="font-bold text-1.2rem ">
              Shown Tasks{' '}
              <span className="text-lg font-[400] text-gray-500 dark:text-gray-300">({numShownIssues})</span>
            </span>
            <button className="text-sm" onClick={() => setHiddenFilters([])}>
              Show all
            </button>
          </div>

          <ul className="flex items-center flex-wrap dark:text-white">
            {Filters.map((filter) => (
              <li key={filter} className="mr-2 2xl:mr-4 mt-2 last:mr-0 text-sm 2xl:text-[1rem]">
                <button
                  style={{ boxShadow: hiddenFilters.includes(filter) ? '' : `inset 0 0 0 2px ${FilterColors[filter]}` }}
                  className={`white-btn border-none px-4 py-2 rounded-full transition-all ${
                    hiddenFilters.includes(filter) ? '!bg-transparent' : 'bg-white dark:!bg-slate-700'
                  }`}
                  onClick={() => toggleFilter(filter)}
                >
                  {filter}
                  <span className="ml-1">({filterIssues(filter).length})</span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* TBD & Today button */}
        <div className="flex flex-wrap gap-y-2 gap-x-4 justify-end pt-1.2rem">
          {TBDIssues.length > 0 && (
            <button
              type="button"
              onClick={() => setShowTBDModal(true)}
              className="
                white-btn
                relative 
                min-h-full 
                min-w-max  
                px-2 
                sm:px-3 
                py-2 
                rounded 
                font-bold "
            >
              TBD Tasks
              <span className="absolute bottom-[60%] -right-3 bg-primary rounded-full h-7 w-7 pl-[1px] pt-[2px] text-center text-white">
                {TBDIssues.length}
              </span>
            </button>
          )}

          <button onClick={onTodayClick} className="px-2 sm:px-4 py-2 rounded white-btn">
            Today
          </button>
        </div>
      </div>

      {/* TBD Modal */}
      {showTBDModal && (
        <Modal closeModal={() => setShowTBDModal(false)}>
          <div className="w-full px-1.2rem lg:px-2rem pb-2.4rem max-h-[75vh] overflow-y-scroll thin-scrollbar">
            <h3 className="text-3xl font-bold mt-8 mb-4 text-center dark:text-gray-200">Tasks with TBD due date</h3>

            <ul className="py-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {TBDIssues.map((issue) => (
                <li
                  key={issue.id}
                  style={{ boxShadow: `inset 0 0 0 2px ${FilterColors[issue.state.name]}` }}
                  className="bg-white rounded p-4 dark:bg-slate-700"
                >
                  <IssueCard issue={issue} />
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      )}
    </>
  )
}

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useAuth } from '../../../../contexts/AuthContext'
import { useGetAllProjectsQuery } from '../../../../redux/slices/api/apiSlice'

export default function CreateUserForm() {
  const { createUser, user } = useAuth()

  const [searchTerm, setSearchTerm] = useState('')

  const { allProjects } = useSelector((state) => state.persistedReducer.allProjects)
  useGetAllProjectsQuery()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    const { email, role, projects } = data

    const res = await createUser(email, role, projects || [])

    if (res?.success) {
      reset()
      toast.success(res?.message, {
        position: 'top-right',
        autoClose: 3000,
      })
    } else {
      toast.error(res?.message, {
        position: 'top-right',
        autoClose: 3000,
      })
    }
  }

  return (
    <form className="flex flex-col relative dark:text-gray-200" onSubmit={handleSubmit(onSubmit)}>
      {/* Email */}
      <div className="flex flex-col max-w-lg">
        <label htmlFor="email" className="font-bold text-lg">
          Email:
        </label>
        <input
          className="input input-bordered w-full rounded-md dark:bg-slate-700 dark:text-gray-200 border-normal"
          type="email"
          id="email"
          placeholder="Email address"
          {...register('email', {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        {errors.email?.type === 'required' && <p className="label text-red-500">This field is required</p>}
        {errors.email?.type === 'pattern' && <p className="label text-red-500">Invalid Email</p>}
      </div>

      {/* Role */}
      <fieldset className="flex my-1.2rem">
        <legend className="font-bold text-lg">Role:</legend>
        <div className="flex items-center mr-2rem">
          <input
            type="radio"
            id="client"
            name="role"
            value="Client"
            {...register('role')}
            className="opacity-0 absolute h-0 w-0 overflow-hidden"
            defaultChecked
          />
          <label htmlFor="client">
            <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
            Client
          </label>
        </div>
        <div className="flex items-center mr-2rem">
          <input
            type="radio"
            id="admin"
            name="role"
            value="Admin"
            {...register('role')}
            className="opacity-0 absolute h-0 w-0 overflow-hidden"
          />
          <label htmlFor="admin">
            <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
            Admin
          </label>
        </div>
        {user.role === 'SuperAdmin' && (
          <div className="flex items-center mr-2rem">
            <input
              type="radio"
              id="super-admin"
              name="role"
              value="SuperAdmin"
              {...register('role')}
              className="opacity-0 absolute h-0 w-0 overflow-hidden"
            />
            <label htmlFor="super-admin">
              <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
              Super Admin
            </label>
          </div>
        )}
      </fieldset>

      {/* Projects */}
      <div className="form-control">
        <label className="font-bold text-lg">Projects:</label>
        <input
          type="text"
          placeholder="Search projects..."
          className="input input-bordered rounded-md max-w-lg dark:bg-slate-700 dark:text-gray-200 border-normal"
          value={searchTerm}
          onChange={({ target }) => setSearchTerm(target.value)}
        />

        <ul className="mt-0.8rem mb-1.2rem grid grid-cols-1 md:grid-cols-2 gap-0.8rem">
          {allProjects
            .filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((project) => (
              <li className="flex items-center" key={project.id}>
                <input
                  type="checkbox"
                  name={project.name}
                  id={project.id}
                  value={project.id}
                  {...register('projects')}
                  className="max-h-4 max-w-4 block mr-2"
                />
                <label htmlFor={project.id}>{project.name}</label>
              </li>
            ))}
        </ul>
      </div>

      <button className="btn btn-primary rounded-md max-w-lg" type="submit">
        Create User
      </button>
    </form>
  )
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allProjectsLoading: true,
  allProjects: [],
};

export const allProjectsSlice = createSlice({
  name: "allProjects",
  initialState: initialState,
  reducers: {
    setAllProjectsData(state, action) {
      state.allProjects = [...action.payload];
      state.allProjectsLoading = false;
    },
    setAllProjectsLoading(state) {
      state.allProjectsLoading = true;
    },
  },
});

export const { setAllProjectsData, setAllProjectsLoading } = allProjectsSlice.actions;

export default allProjectsSlice.reducer;

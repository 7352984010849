import React from "react";

const ExitLeft = ({ height = 15, width = 16, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5001 0.5V2.5H13.5001V12.5H11.5001V14.5H15.5001V0.5H11.5001ZM11.5001 8.5V6.5H3.9001L8.2001 2.2L6.8001 0.8L0.100098 7.5L6.8001 14.2L8.2001 12.8L3.9001 8.5H11.5001Z"
        fill={color}
      />
    </svg>
  );
};

export default ExitLeft;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { addCachedProject, setCachedProjectsLoading, updateProjectIssue } from '../cachedProjects/cachedProjects'
import { setAllProjectsData, setAllProjectsLoading } from '../allProjects/allProjectsSlice'
import { LOCAL_STORAGE_TOKEN_KEY } from '../../../contexts/AuthContext'
import axios from '../../../axios'

let baseUrl = axios.defaults.baseURL

const secPerMin = 60
const secPer30Min = secPerMin * 30

const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
}

export const serverApi = createApi({
  reducerPath: 'serverApi',

  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    cachedProjects: builder.query({
      query: (projectId) => ({
        url: `/dashboard/projects/${projectId}`,
        headers: { Authorization: `JWT ${getToken()}` },
      }),
      keepUnusedDataFor: 0,
      async onQueryStarted(projectId, { dispatch, queryFulfilled }) {
        try {
          dispatch(setCachedProjectsLoading())
          const { data } = await queryFulfilled
          dispatch(addCachedProject(data.data.project))
        } catch (error) {
          console.log(error)
        }
      },
    }),

    getAllProjects: builder.query({
      query: () => ({ url: '/dashboard/projects', headers: { Authorization: `JWT ${getToken()}` } }),
      keepUnusedDataFor: secPer30Min,
      async onQueryStarted(projects, { dispatch, queryFulfilled }) {
        try {
          dispatch(setAllProjectsLoading())
          const { data } = await queryFulfilled
          dispatch(setAllProjectsData(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),

    updateProjectIssue: builder.mutation({
      query: ({ issueId, priority }) => ({
        method: 'PUT',
        url: `/dashboard/issue/${issueId}/priority/${priority}`,
        headers: { Authorization: `JWT ${getToken()}` },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        const issue = data.data.issueUpdate.issue
        dispatch(updateProjectIssue({ issue, projectId: arg.projectId }))
      },
    }),
  }),
})

export const { useCachedProjectsQuery, useGetAllProjectsQuery, useUpdateProjectIssueMutation } = serverApi

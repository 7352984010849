import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AuthProvider from './contexts/AuthContext'
// Components
import SidebarLayout from './components/Layouts/SidebarLayout'
import NotFound from './components/NotFound'
import Navbar from './components/Navbar'
import Home from './components/Home'
import Login from './components/Login'
import PasswordReset from './components/Password/Reset'
import PasswordSet from './components/Password/Set'
// Dashboard
import DashboardProjectView from './components/Dashboard/Project'
import Users from './components/Dashboard/Users'
import CreateUser from './components/Dashboard/Users/CreateUser'
import Settings from './components/Dashboard/Settings'
import User from './components/Dashboard/User'
import ProjectsPage from './components/Dashboard/Projects'
import AdminIssueForm from './components/Dashboard/CreateIssue/AdminForm'
import Learn from './components/Dashboard/Learn'

const DashboardRoutes = [
  { path: '/dashboard', element: <ProjectsPage /> },
  { path: '/dashboard/projects/:projectId', element: <DashboardProjectView /> },
  { path: '/dashboard/users', element: <Users />, isAdmin: true },
  { path: '/dashboard/users/:userId', element: <User />, isAdmin: true },
  { path: '/dashboard/users/new', element: <CreateUser />, isAdmin: true },
  { path: '/dashboard/settings', element: <Settings /> },
  { path: '/dashboard/create-issue', element: <AdminIssueForm />, isAdmin: true },
  { path: '/dashboard/learn', element: <Learn /> },
]

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <AuthProvider>
      <BrowserRouter>
        <Navbar setSidebarOpen={setSidebarOpen} />

        <Routes>
          {/* Dashboard Routes */}
          {DashboardRoutes.map(({ path, element, isAdmin }) => (
            <Route
              key={path}
              path={path}
              element={
                <SidebarLayout sidebarOpen={sidebarOpen} setSideBarOpen={setSidebarOpen} isAdminRoute={isAdmin}>
                  {element}
                </SidebarLayout>
              }
            />
          ))}

          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/password/reset" element={<PasswordReset />}>
            <Route path=":token" element={<PasswordReset />} />
          </Route>
          <Route path="/password/set/:token" element={<PasswordSet />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer newestOnTop position="top-right" />
    </AuthProvider>
  )
}

export default App

import React, { useEffect } from 'react'

export default function Modal({ children, closeModal, showCloseButton = true }) {
  useEffect(() => {
    document.documentElement.classList.add(`!overflow-hidden`)
    return () => document.documentElement.classList.remove(`!overflow-hidden`)
  }, [])

  return (
    <div className="justify-center items-center flex p-4 overflow-hidden fixed inset-0 z-[10000] outline-none focus:outline-none">
      <div className="w-fit max-w-6xl relative my-6 mx-auto z-[10001] shadow-2xl animate-fade-in-up">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col mx-auto bg-base-200 dark:bg-slate-800 outline-none focus:outline-none overflow-hidden">
          {showCloseButton && (
            <button
              type="button"
              className="white-btn p-0 h-8 w-8 grid place-items-center absolute right-4 top-4 rounded-full text-1.2rem pb-2"
              onClick={closeModal}
            >
              ×
            </button>
          )}

          {children}
        </div>
      </div>
      {/* Backdrop */}
      <div className="opacity-50 fixed inset-0 z-[10000] bg-black" onClick={closeModal}></div>
    </div>
  )
}

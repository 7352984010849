import React from "react";

const Ghost = ({ height = 20, width = 20, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 20"
      stroke={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 19.1667L2.83333 17.3334L4.66667 19.1667L6.5 16.4167L8.33333 19.1667L10.1667 16.4167L12 19.1667L13.8333 17.3334L15.6667 19.1667V8.16671C15.6667 4.11642 12.3836 0.833374 8.33333 0.833374C4.28304 0.833374 1 4.11642 1 8.16671V19.1667Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M11.5417 8.16675H12.4584M6.04169 8.16675H6.95835H6.04169Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Ghost;

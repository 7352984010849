import React from "react";
import { useParams } from "react-router-dom";

import NoToken from "../NoToken";
import WithToken from "../WithToken";

const PasswordReset = () => {
  const { token } = useParams();

  return (
    <main className="dark:bg-slate-800">
      <div className="w-full max-w-lg mx-auto pt-4rem">
        <h1 className="text-center font-bold text-4xl dark:text-gray-200">
          {token ? "Reset Your Password" : "Recover Your Password"}
        </h1>

        {token ? <WithToken token={token} /> : <NoToken />}
      </div>
    </main>
  );
};

export default PasswordReset;

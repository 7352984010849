import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import { useAuth } from '../../../contexts/AuthContext'
import { useCachedProjectsQuery } from '../../../redux/slices/api/apiSlice'
import Loader from '../../Shared/Loader'
import Board from './Board'
import Refresh from '../../../SVG/Refresh'
import ClientIssueButton from '../CreateIssue/ClientButton'
import ClientFormModal from '../CreateIssue/ClientFormModal'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export const Boards = ['Timeline', 'Kanban', 'Calendar']
export const Filters = [
  'Blocked',
  'Backlog',
  'Todo',
  'In Progress',
  'Ready for Internal Review',
  'Ready for External Review',
  'Sent for External Review',
  'Revisions',
  'Done',
]
export const FilterColors = {
  Triage: '#7a49ff',
  Blocked: '#C52828',
  Backlog: '#7a49ff',
  Todo: '#5cbdf9',
  'In Progress': '#eeb90b',
  'In Review': '#DC6E1E',
  'Ready for Internal Review': '#F19949',
  'Ready for External Review': '#DB6E1E',
  'Sent for External Review': '#5E6AD2',
  Revisions: '#4CB782',
  Done: '#0F773C',
  Canceled: '#888888',
}

const secPerMin = 60
const secPer30Min = secPerMin * 30
const msPer30Min = secPer30Min * 1000

export default function ProjectView() {
  const { user } = useAuth()

  const [searchParams, setSearchParams] = useSearchParams()
  const boardParam = searchParams.get('board')

  const [boardView, setBoardView] = useState(boardParam || Boards[0])
  const [forceRefresh, setForceRefresh] = useState(false)
  const [showIssueForm, setShowIssueForm] = useState(false)

  const { projectId } = useParams()

  const { cachedProjects, isLoading } = useSelector((state) => state.persistedReducer.cachedProjects)

  const project = cachedProjects[projectId]
  const msSinceLastFetch = dayjs().diff(project?.fetchedAt)

  const [teamFilters, setTeamFilters] = useState([...(project?.teams.nodes.map((team) => team.name) || [])])

  useCachedProjectsQuery(projectId, {
    skip: msSinceLastFetch < msPer30Min && Boolean(project) && !forceRefresh,
  })

  const getValidIssues = () => {
    return project.issues.nodes.filter((issue) => Filters.includes(issue.state.name) || issue.state.name === 'Triage')
  }

  const handleBoardChange = (board) => {
    setSearchParams(`board=${board}`)
  }

  useEffect(() => {
    boardParam && setBoardView(boardParam)
  }, [boardParam])

  useEffect(() => {
    forceRefresh && setForceRefresh(false)
  }, [forceRefresh])

  useEffect(() => {
    setTeamFilters([...(project?.teams.nodes.map((team) => team.name) || [])])
  }, [project])

  if (isLoading || !project)
    return (
      <div className="flex justify-center items-center h-full">
        <Loader />
      </div>
    )

  console.log(project)

  return (
    <>
      <div id="project-view-container" className="pb-2rem px-1.2rem lg:px-2rem w-full h-full relative">
        {/* Header */}
        <div className="w-full py-5 flex flex-col max-w-screen-2xl mx-auto xl:flex-row xl:justify-between xl:items-start">
          <div className="text-base-content font-bold text-[1.4rem] sm:text-4xl dark:text-gray-200">
            <h1>{project.name}</h1>

            <div className="flex items-center my-2 overflow-x-auto">
              <span className="text-[1rem] mr-2 sm:mr-4 font-normal whitespace-nowrap">
                {getValidIssues().length} {getValidIssues().length === 1 ? 'task' : 'tasks'}
              </span>
              <span className="text-[1rem] mr-2 sm:mr-4 font-normal whitespace-nowrap">•</span>
              <span className="text-[1rem] mr-2 sm:mr-2 font-normal whitespace-nowrap">
                Updated {dayjs(project.fetchedAt).fromNow()}
              </span>
              {user.role === 'SuperAdmin' && (
                <button
                  className="white-btn py-[.1rem] px-1 rounded text-sm inline-flex items-center font-normal"
                  onClick={() => setForceRefresh(true)}
                >
                  <Refresh height={16} />
                </button>
              )}
              <span className="text-[1rem] mx-2 sm:mr-4 font-normal whitespace-nowrap">•</span>
              <span></span>
              <span className="text-[1rem] mr-2 sm:mr-2 font-normal whitespace-nowrap">Teams:</span>
              {project.teams.nodes.map(({ name }) => (
                <button
                  key={name}
                  onClick={() =>
                    teamFilters.includes(name)
                      ? setTeamFilters((prev) => prev.filter((n) => n !== name))
                      : setTeamFilters((prev) => [...prev, name])
                  }
                  className={`white-btn mr-1 py-[.1rem] px-1 rounded text-sm inline-flex items-center font-normal 
                  ${!teamFilters.includes(name) && 'opacity-40'}`}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>

          {/* Board Btns */}
          <div className="tracking-wide border-t xl:border-t-0 w-full xl:w-max flex">
            {Boards.map((board) => (
              <button
                key={board}
                className={`white-btn mr-2 sm:mr-4 xl:ml-4 xl:mr-0 mt-4 xl:mt-0 px-2 sm:px-4 py-1 lg:py-2 tracking-wide rounded transition-all
                 ${boardView === board && '!bg-primary !text-white'}
                `}
                onClick={() => handleBoardChange(board)}
              >
                {board}
              </button>
            ))}
          </div>
        </div>

        <Board
          boardView={boardView}
          project={{
            ...project,
            issues: {
              nodes:
                project.issues.nodes.filter((issue) =>
                  issue.team?.name ? teamFilters.includes(issue.team.name) : true
                ) || [],
            },
          }}
        />
      </div>

      <ClientIssueButton onClick={() => setShowIssueForm(true)} />
      {showIssueForm && <ClientFormModal closeModal={() => setShowIssueForm(false)} projectName={project.name} />}
    </>
  )
}

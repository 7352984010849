import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import AdminIssueButton from '../Dashboard/CreateIssue/AdminButton'
import GettingStarted from '../Dashboard/GettingStarted'

import Sidebar from '../Sidebar'

export default function SidebarLayout({ sidebarOpen, setSideBarOpen, isAdminRoute, children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId } = useParams()

  const { user, token, verifyToken, logout, isAdmin } = useAuth()

  useEffect(() => {
    if (!token) return navigate('/login', { replace: true })

    document.documentElement.classList.add(`overflow-y-scroll`)

    if (!user?._id) {
      ;(async () => {
        const res = await verifyToken(token)
        const validatedUser = res?.user
        if (validatedUser) {
          // Prevent non-admins from accessing admin pages
          const isAdmin = validatedUser.role === 'Admin' || validatedUser.role === 'SuperAdmin'
          if (isAdminRoute && !isAdmin) return navigate('/dashboard', { replace: true })
        } else {
          logout()
          navigate('/login', { replace: true })
        }
      })()
    }
    return () => document.documentElement.classList.remove(`overflow-y-scroll`)
  }, [user?._id, token, logout, navigate, verifyToken, isAdminRoute])

  return (
    user && (
      <div className="flex">
        <Sidebar sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen} />
        <main className="flex-1 overflow-hidden min-h-full flex flex-col justify-between mt-0 lg:-mt-[85px] dark:bg-slate-800 pb-4rem">
          {children}

          <GettingStarted />

          {/* Only show for admins who are not on the admin issue page or project page */}
          {isAdmin && location.pathname !== '/dashboard/create-issue' && !projectId && <AdminIssueButton />}
        </main>
      </div>
    )
  )
}

import React from 'react'
import dayjs from 'dayjs'

const DateBar = ({ chartId, getNumDaysShown, getItemsDateRange, dateWidth, scrollToDate }) => {
  const numDaysShown = getNumDaysShown()
  const { lastDate } = getItemsDateRange

  const dates = Array(numDaysShown)
    .fill()
    .map((_, i) => dayjs(lastDate).subtract(i, 'day').format('YYYY-MMM-D'))
    .reverse()

  return dates.map((date) => {
    const splits = date.split('-')
    const year = splits[0]
    const month = splits[1]
    const day = splits[2]
    const isToday = dayjs().isSame(date, 'day')

    const opacity = () => {
      if (dateWidth > 20) return 1
      const dayInterval = 4 // Number of days until another date is shown on low zoom
      if ((parseInt(day) + (dayInterval - (parseInt(dayjs().get('D')) % dayInterval))) % dayInterval !== 0) return 0
      return 1
    }

    return (
      <button
        className="text-sm relative h-full"
        id={chartId + '-' + date}
        key={date}
        onClick={() => scrollToDate(date)}
        style={{ width: `${dateWidth}px` }}
      >
        <div className="absolute bottom-0 -translate-x-[50%]" style={{ left: `${dateWidth / 2}px` }}>
          {day === '1' && (
            <span>
              {month === 'Jan' && year}
              {month}
            </span>
          )}
          <span
            style={{ opacity: opacity() }}
            className={`date grid place-items-center mx-auto ${isToday && 'text-white'}`}
          >
            {isToday && <span className="bg-[#3350E8] h-5 w-5 absolute z-[-1] rounded-full" />}
            {day}
          </span>
        </div>
      </button>
    )
  })
}

export default DateBar

import React from "react";
import Plus from "../../../SVG/Plus";

const ClientIssueButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="
        fixed 
        bottom-[1rem]
        right-[1rem]
        z-[9999] 
        flex 
        items-center 
        py-3
        px-4
        text-white 
        shadow-md 
        border
        border-dark
        bg-black 
        rounded-full 
        cursor-pointer
        hover:!bg-white
        hover:text-black 
        active:!bg-primary
        active:text-white
        transition-all
        dark:bg-slate-700
      "
    >
      <span className="mr-2">Make a Request</span>
      <Plus />
    </button>
  );
};

export default ClientIssueButton;

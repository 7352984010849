import React, { useCallback } from 'react'
import dayjs from 'dayjs'

import ChildArrow from '../../../SVG/ChildArrow'
import Expand from '../../../SVG/Expand'
import Collapse from '../../../SVG/Collapse'

const ItemTitle = ({ item, idx, level = 1, ...commonProps }) => {
  const {
    CollapsedRowHeight,
    RowHeight,
    itemChildrenMap,
    collapsedItemIds,
    setCollapsedItemIds,
    isItemParentCollapsed,
    scrollToDate,
    handleRowMouseOver,
  } = commonProps

  const collapseItem = (e, id) => {
    e.stopPropagation()
    if (collapsedItemIds.get(id)) return
    setCollapsedItemIds((prev) => new Map([...prev, [id, true]]))
  }

  const expandItem = (e, id) => {
    e.stopPropagation()
    setCollapsedItemIds((prev) => {
      const newState = new Map(prev)
      newState.delete(id)
      return newState
    })
  }

  const deepCountChildren = useCallback(
    (item) => {
      const countChildren = (itemId, count = 0) => {
        const childs = itemChildrenMap.get(itemId)
        childs?.forEach((child) => (count += countChildren(child.id)))
        return count + childs?.length || 0
      }
      return countChildren(item.id)
    },
    [itemChildrenMap]
  )

  /**
   *
   * @returns the number of children that are collapsed with a given item
   */
  const deepCountCollapsedChildren = useCallback(
    (initialItem) => {
      const countCollapsedChildren = (item, count = 0) => {
        const childs = itemChildrenMap.get(item.id)
        childs?.forEach((child) => (count += countCollapsedChildren(child, 0)))
        return count + (collapsedItemIds.get(item.id) ? deepCountChildren(item) - count : 0)
      }
      return countCollapsedChildren(initialItem)
    },
    [itemChildrenMap, deepCountChildren, collapsedItemIds]
  )

  // Count children of previous sibling
  const parent = itemChildrenMap.get(item.parentId || 'root')
  const prevSibling = parent[idx - 1]
  const hasDynamicSizedArrows = prevSibling && item.parentId
  let numOfPrevSibChildren = hasDynamicSizedArrows ? deepCountChildren(prevSibling) + 1 : 0 // + 1 to include the prev sibling itself
  let numOfPrevSibChildrenCollapsed = hasDynamicSizedArrows ? deepCountCollapsedChildren(prevSibling) : 0

  const { isParentCollapsed, collapsedParentId } = isItemParentCollapsed(item)
  const isItemCollapsed = collapsedItemIds.get(item.id)
  const height = isParentCollapsed ? CollapsedRowHeight : RowHeight

  const arrowBarHeight =
    (numOfPrevSibChildren - numOfPrevSibChildrenCollapsed) * RowHeight +
    numOfPrevSibChildrenCollapsed * CollapsedRowHeight

  const arrowStyles =
    idx > 0
      ? {
          height: `${arrowBarHeight}px`,
          top: `-${arrowBarHeight - 10}px`,
          background: item.arrowColor || item.barColor,
        }
      : {
          height: `${27}px`,
          top: `-${17}px`,
          background: item.arrowColor || item.barColor,
        }

  const children = itemChildrenMap.get(item.id)

  return (
    <>
      <button
        data-item-id={item.id}
        className="gantt-title w-full flex items-center pr-1 text-left transition-[height]"
        onMouseEnter={() => handleRowMouseOver(item.id)}
        onMouseLeave={() => handleRowMouseOver(-1)}
        onClick={(e) =>
          isParentCollapsed
            ? expandItem(e, collapsedParentId)
            : scrollToDate(item.startDate || dayjs().format('YYYY-MM-DD'), false)
        }
        onDoubleClick={isItemCollapsed ? (e) => expandItem(e, item.id) : (e) => collapseItem(e, item.id)}
        style={{
          paddingLeft: `${level * (level > 1 ? 12 : 8)}px`,
          height: `${height}px`,
        }}
      >
        {/* Child Arrow */}
        {item.parentId && (
          <span
            className={`relative z-[0] opacity-[var(--child-opacity,1)]
               ${!isParentCollapsed && 'transition delay-75'}`}
          >
            <span className="w-[1px] bg-current absolute block left-[3px] transition-all" style={arrowStyles} />
            <span className="pb-[3px] block" style={{ color: item.arrowColor || item.barColor }}>
              <ChildArrow height={16} width={16} />
            </span>
          </span>
        )}

        {/* Title */}
        <span className={`truncate transition opacity-[var(--child-opacity,1)] ${level > 1 && 'text-sm'}`}>
          {item.title}
        </span>

        {/* Collapse / Expand Btn */}
        {Boolean(children?.length) && !isParentCollapsed && (
          <span
            className="white-btn p-[2px] ml-auto border-normal text-gray-400 dark:text-slate-400 hover:text-gray-200 dark:hover:text-slate-200"
            onClick={isItemCollapsed ? (e) => expandItem(e, item.id) : (e) => collapseItem(e, item.id)}
          >
            {isItemCollapsed ? <Expand height={14} width={14} /> : <Collapse height={14} width={14} />}
          </span>
        )}
      </button>

      <div className={`${isItemCollapsed && '[--child-opacity:0]'}`}>
        {children?.map((child, i) => (
          <ItemTitle key={child.id + '_title'} item={child} idx={i} level={level + 1} {...commonProps} />
        ))}
      </div>
    </>
  )
}
export default ItemTitle

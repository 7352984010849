import React from "react";

const Exclamation = ({ width = 4, height = 18, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H4V11H0V0ZM0 18V14H4V18H0Z" fill={color} />
    </svg>
  );
};

export default Exclamation;

import React from "react";

const Refresh = ({ height = 20, width = 20, color = "currentColor" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 352 352"
      height={height}
      width={width}
      fill={color}
    >
      <g>
        <path
          d="M206,149.352c0,7.156,4.635,10.416,8.941,10.416c2.025,0,4.095-0.649,6.151-1.931l73.513-45.825
		c3.485-2.17,5.483-5.441,5.483-8.973c0-3.55-2.016-6.842-5.528-9.03l-73.469-45.827c-2.057-1.28-4.125-1.93-6.15-1.93
		c-4.307,0-8.941,3.259-8.941,10.415V85H50.375C22.598,85,0,107.26,0,134.622V170c0,11.046,8.954,20,20,20s20-8.954,20-20v-35.378
		c0-5.216,4.751-9.622,10.375-9.622H206V149.352z"
        />
        <path
          d="M332,164c-11.046,0-20,8.954-20,20v34.569c0,5.459-4.945,10.431-10.375,10.431H146v-26.352
		c0-7.156-4.635-10.416-8.942-10.416c-2.025,0-4.094,0.65-6.151,1.931l-73.513,45.824c-3.485,2.171-5.484,5.441-5.484,8.974
		c0,3.55,2.016,6.842,5.529,9.03l73.468,45.827c2.056,1.281,4.125,1.93,6.15,1.93c4.307,0,8.942-3.259,8.942-10.415V269h155.625
		c13.328,0,25.948-5.293,35.535-14.903c9.569-9.594,14.84-22.211,14.84-35.527V184C352,172.954,343.046,164,332,164z"
        />
      </g>
    </svg>
  );
};

export default Refresh;

import React from "react";

const Garbage = ({ width = 14, height = 17, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.01838 0.5V1.72324H0.394531V3.974H13.6055V1.72324H8.98169V0.5H5.01838ZM1.10401 5.68655V16.5H12.8961V5.68655H1.10401Z"
        fill={color}
      />
    </svg>
  );
};

export default Garbage;

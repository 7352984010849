import React from "react";

const Pause = ({ height = 14, width = 12, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H4V14H0V0ZM8 0H12V14H8V0Z" fill={color} />
    </svg>
  );
};

export default Pause;

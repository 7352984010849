import { useSelector } from 'react-redux'

import Loader from '../../Shared/Loader'
import ProjectsView from './ProjectsView'
import { useGetAllProjectsQuery } from '../../../redux/slices/api/apiSlice'
import { useAuth } from '../../../contexts/AuthContext'
// SVGs
import Pause from '../../../SVG/Pause'
import Calendar from '../../../SVG/Calendar'
import Play from '../../../SVG/Play'
import Completed from '../../../SVG/Completed'
import Canceled from '../../../SVG/Canceled'
import Exclamation from '../../../SVG/Exclamation'

export const ProjectStateColors = {
  backlog: '#F2994A',
  planned: '#B0B0B3',
  started: '#F2C94B',
  paused: '#666970',
  completed: '#5E6AD2',
  canceled: '#37373C',
}

const IconHeight = 15

export const ProjectStateIcons = {
  backlog: <Exclamation height={IconHeight} width={IconHeight} />,
  planned: <Calendar height={IconHeight} width={IconHeight} />,
  started: <Play height={IconHeight} width={IconHeight} />,
  paused: <Pause height={IconHeight} width={IconHeight} />,
  completed: <Completed height={IconHeight + 2} width={IconHeight + 2} />,
  canceled: <Canceled height={IconHeight} width={IconHeight} />,
}

export default function ProjectsPage({ userData = null, setUserData = null }) {
  const { user, isAdmin } = useAuth()

  const projectIdArr = (userData || user).projects

  useGetAllProjectsQuery()

  const { allProjectsLoading, allProjects } = useSelector((state) => state.persistedReducer.allProjects)

  const renderLoader = () => {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader />
      </div>
    )
  }

  const userProjects = []
  const otherProjects = []
  allProjects.filter((p) => (projectIdArr.includes(p.id) ? userProjects.push(p) : otherProjects.push(p)))

  return (
    <div className="max-w-screen-2xl mx-auto px-1.2rem lg:px-2rem w-full h-full">
      {allProjectsLoading && renderLoader()}

      {!allProjectsLoading && (
        <div>
          <ProjectsView
            title={userData ? 'Projects' : 'Your Projects'}
            projects={userProjects}
            userData={userData}
            setUserData={setUserData}
            searchable={isAdmin}
            collapsable={isAdmin}
            showGantt={!userData && userProjects.length > 1}
          />

          <div className="pt-4rem" />

          {isAdmin && (
            <ProjectsView
              title={'Other Projects'}
              projects={otherProjects}
              collapsable
              defaultCollapsed
              searchable
              userData={userData}
              setUserData={setUserData}
            />
          )}
        </div>
      )}
    </div>
  )
}

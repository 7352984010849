import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../../contexts/AuthContext";
import Checkmark from "../../../SVG/Checkmark";

const UserCard = ({ cardUser }) => {
  const { user } = useAuth();

  return (
    <Link to={`/dashboard/users/${cardUser.email}`}>
      <div
        className={`card border border-dark relative shadow-lg p-0.8rem bg-base-100 rounded-md hover:shadow-[0_10px_15px_rgb(0,0,0,0.25)] transition-all dark:bg-slate-600 dark:text-gray-200 
        ${
          user.email === cardUser.email &&
          `shadow-[inset_0_0_0_2px_#3350E8,0_10px_15px_rgb(0,0,0,0.1)] 
           hover:!shadow-[inset_0_0_0_2px_#3350E8,0_10px_15px_rgb(0,0,0,0.25)] 
           dark:hover:!shadow-[inset_0_0_0_2px_#3350E8,0_10px_15px_rgb(0,0,0,0.75)]`
        }`}
      >
        <p className="border-b border-dark font-[600] text-lg pb-2 mb-2">{cardUser.email}</p>

        <div className="flex items-center justify-between">
          <h4 className="font-semibold">
            Projects <span className="text-gray-600 font-[400] dark:text-gray-200">({cardUser.projects.length})</span>
          </h4>

          {cardUser.isActive && (
            <span className="flex items-center w-fit text-sm">
              <span className="mr-1">Verified Email</span>
              <Checkmark height={18} width={18} />
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default UserCard;

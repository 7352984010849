import { useCallback, useEffect, useState } from 'react'
import * as dayjs from 'dayjs'

import { FilterColors, Filters } from '..'
import IssueFilter from './IssueFilter'
import IssueCard from './IssueCard'
import LeftArrow from '../../../../SVG/LeftArrow'
import RightArrow from '../../../../SVG/RightArrow'

const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const Days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export default function Calendar({ project }) {
  const [hiddenFilters, setHiddenFilters] = useState([])

  const date = new Date()
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())
  const [numOfDays, setNumOfDays] = useState([])
  const [emptyDays, setEmptyDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(date.getDate())

  const getNoOfDays = useCallback(() => {
    let i
    let daysInMonth = new Date(year, month + 1, 0).getDate()

    // find where to start calendar day of week
    let dayOfWeek = new Date(year, month).getDay()
    let emptyDaysArray = []
    for (i = 1; i <= dayOfWeek; i++) {
      emptyDaysArray.push(i)
    }

    let daysArray = []
    for (i = 1; i <= daysInMonth; i++) {
      daysArray.push(i)
    }

    setEmptyDays(emptyDaysArray)
    setNumOfDays(daysArray)
  }, [month, year])

  useEffect(() => {
    getNoOfDays()
  }, [month, getNoOfDays])

  const nextMonth = () => {
    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  const prevMonth = () => {
    if (month === 0) {
      setMonth(11)
      setYear(year - 1)
    } else {
      setMonth(month - 1)
    }
  }

  const resetCalendar = () => {
    const date = new Date()
    setMonth(date.getMonth())
    setYear(date.getFullYear())
    setSelectedDate(date.getDate())
  }

  const isToday = (date) => {
    const today = new Date()
    const d = new Date(year, month, date)
    return today.toDateString() === d.toDateString()
  }

  const isDaySelected = (date) => {
    const day = new Date(year, month, date)
    const selected = new Date(year, month, selectedDate)
    return dayjs(day).isSame(selected, 'day')
  }

  const getFilteredIssuesForDay = (date) => {
    return project.issues.nodes.filter((issue) => {
      const isValidIssue = Filters.includes(issue.state.name) || issue.state.name === 'Triage'
      const isSameDate = dayjs(new Date(year, month, date)).isSame(issue.completedAt || issue.dueDate, 'day')
      const isHidden = hiddenFilters.includes('Backlog')
        ? [...hiddenFilters, 'Triage'].includes(issue.state.name)
        : hiddenFilters.includes(issue.state.name)

      return isValidIssue && isSameDate && !isHidden
    })
  }

  return (
    <div className="w-full px-1">
      <div className="xl:flex max-w-screen-2xl mx-auto">
        {/* Calendar Container */}
        <div className="flex-1">
          <IssueFilter
            issues={project.issues}
            hiddenFilters={hiddenFilters}
            setHiddenFilters={setHiddenFilters}
            onTodayClick={resetCalendar}
          />

          {/* Header */}
          <div className="bg-white rounded shadow mt-4 dark:bg-slate-700 ">
            <div className="flex items-center justify-between border-b border-normal px-2 sm:px-6 py-2 sm:py-4">
              <div onClick={resetCalendar} className="cursor-pointer">
                <span className="text-xl sm:text-3xl font-bold text-gray-800 dark:text-gray-200">
                  {MonthNames[month]}
                </span>
                <span className="ml-1 sm:text-xl text-gray-600 dark:text-gray-400 font-normal">{year}</span>
              </div>

              {/* Month Btns */}
              <div className="border border-normal rounded text-gray-400">
                <button
                  type="button"
                  onClick={prevMonth}
                  className={
                    'leading-none rounded transition-all inline-flex cursor-pointer hover:bg-gray-200 dark:hover:bg-slate-800 px-2 py-1 m-1 items-center active:outline-none'
                  }
                >
                  <LeftArrow />
                </button>
                <div className="border-r border-normal inline-flex h-5" />
                <button
                  type="button"
                  onClick={nextMonth}
                  className={
                    'leading-none rounded transition-all inline-flex cursor-pointer hover:bg-gray-200 dark:hover:bg-slate-800 px-2 py-1 m-1 items-center active:outline-none'
                  }
                >
                  <RightArrow />
                </button>
              </div>
            </div>

            {/* Day Names */}
            <div className="grid grid-cols-7 rounded-b bg-white dark:bg-slate-700">
              {Days.map((day) => (
                <div key={day} className="px-2 py-2">
                  <div className="text-gray-600 text-sm uppercase tracking-wide font-bold text-center dark:text-gray-300">
                    {day}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Calendar Days */}
          <div className="grid grid-cols-7 gap-[1px] md:gap-[3px] mt-[3px] max-w-full">
            {emptyDays.map((emptyDay, i) => (
              <div
                key={'empty-day-' + month + '-' + emptyDay}
                className="text-center shadow h-[4rem] sm:h-[5rem] md:h-[7rem] 2xl:h-[9rem] bg-white dark:bg-slate-700 rounded"
              />
            ))}

            {numOfDays.map((date, i) => (
              <div
                onClick={() => setSelectedDate(date)}
                key={'calendar-day-' + month + '-' + date}
                className={`p-[1px] lg:p-1 pb-0 shadow relative h-[4rem] sm:h-[5rem] md:h-[7rem] 2xl:h-[9rem] bg-white dark:bg-slate-700 rounded flex flex-col cursor-pointer
                ${isDaySelected(date) && '!shadow-[inset_0_0_0_1px#3350E8] bg-gray-100 dark:!bg-slate-800'}`}
              >
                {/* Number */}
                <div
                  className={`grid place-items-center text-sm max-w-6 w-6 h-full max-h-6 text-center dark:text-gray-300 rounded-full
                ${isToday(date) && 'bg-primary !text-white'}`}
                >
                  {date}
                </div>

                {/* Issues */}
                <ul className="overflow-y-auto h-full mb-[2px] thin-scrollbar">
                  {getFilteredIssuesForDay(date).map((issue) => (
                    <li
                      key={issue.id + '-in-calendar'}
                      style={{ background: FilterColors[issue.state.name] }}
                      className="m-1 lg:my-2 p-[3px] lg:p-2 bg-white rounded dark:bg-slate-800 text-white"
                    >
                      <p
                        className={`text-sm truncate leading-tight hidden lg:block ${
                          issue.state.name === 'Canceled' && 'line-through'
                        }`}
                      >
                        {issue.title}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Issue List */}
        <div className="py-1.2rem w-full transition-all overflow-hidden xl:w-[15rem] 2xl:w-[20rem] xl:ml-1.2rem xl:pt-[6.5rem]">
          <span className="font-bold text-xl border-b border-normal w-full block dark:text-gray-100">
            {dayjs(new Date(year, month, selectedDate)).format('dddd, MMM D')}
          </span>

          {getFilteredIssuesForDay(selectedDate).length ? (
            <ul className="pt-4 grid items-start grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-1 gap-1.2rem">
              {getFilteredIssuesForDay(selectedDate).map((issue) => (
                <li
                  key={issue.id}
                  style={{ boxShadow: `inset 0 0 0 2px ${FilterColors[issue.state.name]}` }}
                  className="bg-white rounded p-4 dark:bg-slate-600"
                >
                  <IssueCard issue={issue} />
                </li>
              ))}
            </ul>
          ) : (
            <p className="my-3 text-gray-500">No tasks</p>
          )}
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useGetAllProjectsQuery } from '../../../redux/slices/api/apiSlice'
import { useAuth } from '../../../contexts/AuthContext'
import Plus from '../../../SVG/Plus'
import Minus from '../../../SVG/Minus'
import Garbage from '../../../SVG/Garbage'

import './Form.css'

const maxImageBytes = 246000
const bytePerKB = 1024
const maxKB = maxImageBytes / bytePerKB
const allowedImagesTypes = ['png', 'jpeg', 'jpg', 'gif', 'webp']

const AdminIssueForm = () => {
  const { user, postsTriageIssue } = useAuth()

  const { allProjects } = useSelector((state) => state.persistedReducer.allProjects)
  useGetAllProjectsQuery()
  // Filter user linear projects
  const userProjectIdMap = new Map()
  user.projects.forEach((id) => userProjectIdMap.set(id, true))
  const userProjects = allProjects.filter((project) => userProjectIdMap.get(project.id))

  const [loading, setLoading] = useState(false)
  const [resourceCount, setResourceCount] = useState(1)
  const [imageDataUrls, setImageDataUrls] = useState([])

  const totalImagesKB = imageDataUrls.join('').length / bytePerKB

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    if (totalImagesKB > maxKB)
      return toast.error('File size exceeds limit', {
        position: 'top-right',
        autoClose: 3000,
      })

    const resources = []
    for (let i = 0; i < resourceCount; i++) {
      const resource = data[`resource_${i}`]
      if (resource) resources.push(resource + '\n')
      delete data[`resource_${i}`]
    }

    const issueData = {
      ...data,
      details: document.querySelector('#details').innerHTML,
      resources: resources.join(''),
      imageDataUrls,
      submittedBy: user.email,
    }

    setLoading(true)
    const res = await postsTriageIssue(issueData)
    setLoading(false)

    if (res?.success) {
      resetForm()
      toast.success(res?.message, {
        position: 'top-right',
        autoClose: 3000,
      })
    } else {
      toast.error(res?.message, {
        position: 'top-right',
        autoClose: 3000,
      })
    }
  }

  const isFileValid = (file) => {
    if (!file.type) return false
    if (!file.type.startsWith('image/')) return false
    return allowedImagesTypes.includes(file.type.split('/')[1])
  }

  const readImage = (file) => {
    if (!isFileValid(file)) {
      return toast.error('File type not allowed', {
        position: 'top-right',
        autoClose: 3000,
      })
    }

    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      const result = event.target.result
      setImageDataUrls((prev) => [...(prev || []), result])
    })
    reader.readAsDataURL(file)
  }

  const getMetadataForFileList = (fileList) => {
    const files = []
    for (const file of fileList) {
      // Not supported in Safari for iOS.
      const name = file.name ? file.name : 'NOT SUPPORTED'
      // Not supported in Firefox for Android or Opera for Android.
      const type = file.type ? file.type : 'NOT SUPPORTED'
      // Unknown cross-browser support.
      const size = file.size ? file.size : 'NOT SUPPORTED'
      files.push({ file, name, type, size })
    }
    return files
  }

  const handleFileChange = (e) => {
    const fileList = e.target.files
    const images = getMetadataForFileList(fileList)
    for (const image of images) {
      readImage(image.file)
    }
    e.target.value = ''
  }

  const resetForm = () => {
    reset()
    setImageDataUrls([])
    setResourceCount(1)
    document.querySelector('#details').innerHTML = ''
  }

  const handlePasteEvent = (e) => {
    e.preventDefault()
    // Grab as both text and html
    const textString = e.clipboardData.getData('Text')
    const htmlString = e.clipboardData.getData('text/html')

    const wrapper = document.createElement('div')
    wrapper.innerHTML = htmlString || textString // Default to text if html not found
    // loop through all DOM elements inside the wrapper and remove style and class
    var tags = wrapper.querySelectorAll('*')
    for (let el of tags) {
      el.style = ''

      if (el.nodeName === 'A') {
        el.classList = ['details-link']
        el.contentEditable = 'false'
        el.target = '_blank'
      } else el.classList = []
    }
    // Paste
    e.target.closest(':not(br)').innerHTML += wrapper.innerHTML
  }

  const renderSubmitButton = () => (
    <button
      className="btn btn-primary rounded-md w-full max-w-[34rem] my-5 !text-gray-100"
      type="submit"
      disabled={loading}
    >
      {loading ? 'Submitting' : 'Submit Issue'}
    </button>
  )

  return (
    <div className="max-w-screen-2xl w-full mx-auto px-1.2rem lg:px-2rem pb-2.4rem">
      <h1 className="py-5 font-semibold text-4xl dark:text-gray-200">Create Issue</h1>

      <form className="flex flex-col relative dark:text-gray-200" onSubmit={handleSubmit(onSubmit)}>
        <div className="xl:flex">
          {/* 
          
          Client Name 
          
          */}
          <div className="w-full max-w-[34rem] xl:mr-2rem">
            <div className="flex flex-col my-5">
              <label htmlFor="email" className="font-bold text-lg flex justify-between items-center">
                Client: <span className="text-xs text-gray-500 dark:text-slate-400">(Required)</span>
              </label>
              <input
                type="text"
                id="client"
                className="input input-bordered w-full rounded-md dark:bg-slate-700 dark:text-gray-200 border-normal"
                {...register('client', { required: true })}
              />
              {errors.client?.type === 'required' && <p className="label text-red-500">This field is required</p>}
            </div>

            {/* 
            
            Issue Title 
            
            */}
            <div className="flex flex-col my-5">
              <label htmlFor="email" className="font-bold text-lg flex justify-between items-center">
                Issue Title: <span className="text-xs text-gray-500 dark:text-slate-400">(Required)</span>
              </label>
              <input
                type="text"
                id="issueTitle"
                className="input input-bordered w-full rounded-md dark:bg-slate-700 dark:text-gray-200 border-normal"
                {...register('issueTitle', { required: true })}
              />
              {errors.issueTitle?.type === 'required' && <p className="label text-red-500">This field is required</p>}
            </div>

            {/* 
            
            Details 
            
            */}
            <div className="flex flex-col my-5">
              <label htmlFor="email" className="font-bold text-lg">
                Details:
              </label>
              <div
                role="textbox"
                contentEditable
                required
                onPaste={handlePasteEvent}
                id="details"
                placeholder="Summary of issue"
                rows={5}
                className="textarea input-bordered w-full rounded-md dark:bg-slate-700 dark:text-gray-200 whitespace-pre-wrap min-h-[6rem] border-normal"
              />
              {errors.details?.type === 'required' && <p className="label text-red-500">This field is required</p>}
            </div>

            {/* 
            
            Project Select 
            
            */}
            <div>
              <label htmlFor="email" className="font-bold text-lg">
                Project:
              </label>
              <select
                {...register('projectId')}
                className="select w-full rounded-md dark:bg-slate-700 dark:text-gray-200 border-normal"
              >
                <option value={''}>Select a Project</option>
                {userProjects.map((proj) => (
                  <option value={proj.id} key={proj.id}>
                    {proj.name}
                  </option>
                ))}
              </select>
            </div>

            {/* 
            
            Priority 
            
            */}
            <fieldset className="flex flex-wrap my-5">
              <legend className="font-bold text-lg mb-2">Priority:</legend>

              <div className="flex items-center mr-2rem mb-2">
                <input
                  {...register('priority', { required: true })}
                  type="radio"
                  id="no-priority"
                  value="0"
                  className="h-4 w-4 hidden"
                  defaultChecked
                />
                <label htmlFor="no-priority" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  No Priority
                </label>
              </div>
              <div className="flex items-center mr-2rem mb-2">
                <input
                  {...register('priority', { required: true })}
                  type="radio"
                  id="urgent"
                  value="1"
                  className="h-4 w-4 hidden"
                />
                <label htmlFor="urgent" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  Urgent
                </label>
              </div>
              <div className="flex items-center mr-2rem mb-2">
                <input
                  {...register('priority', { required: true })}
                  type="radio"
                  id="high"
                  value="2"
                  className="h-4 w-4 hidden"
                />
                <label htmlFor="high" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  High
                </label>
              </div>
              <div className="flex items-center mr-2rem mb-2">
                <input
                  {...register('priority', { required: true })}
                  type="radio"
                  id="medium"
                  value="3"
                  className="h-4 w-4 hidden"
                />
                <label htmlFor="medium" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  Medium
                </label>
              </div>
              <div className="flex items-center mb-2">
                <input
                  {...register('priority', { required: true })}
                  type="radio"
                  id="low"
                  value="4"
                  className="h-4 w-4 hidden"
                />
                <label htmlFor="low" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  Low
                </label>
              </div>
            </fieldset>

            {/* 
            
            Is Quote/Retainer 
            
            */}
            <fieldset className="flex flex-wrap my-5">
              <legend className="font-bold text-lg mb-2">Do we have a quote signed / is this under a retainer?</legend>

              <div className="flex items-center mr-2rem mb-2">
                <input
                  {...register('isQuoted', { required: true })}
                  type="radio"
                  id="isQuoted-yes"
                  value="Yes"
                  className="h-4 w-4 hidden"
                />
                <label htmlFor="isQuoted-yes" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  Yes
                </label>
              </div>
              <div className="flex items-center mr-2rem mb-2">
                <input
                  {...register('isQuoted', { required: true })}
                  type="radio"
                  id="isQuoted-no"
                  value="No"
                  className="h-4 w-4 hidden"
                />
                <label htmlFor="isQuoted-no" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  No
                </label>
              </div>
              <div className="flex items-center mr-2rem mb-2">
                <input
                  {...register('isQuoted', { required: true })}
                  type="radio"
                  id="isQuoted-unsure"
                  value="Unsure"
                  className="h-4 w-4 hidden"
                  defaultChecked
                />
                <label htmlFor="isQuoted-unsure" className="cursor-pointer">
                  <span className="w-4 h-4 -mb-[2px] rounded-sm inline-block mr-1 border border-normal"></span>
                  Unsure
                </label>
              </div>
            </fieldset>

            {/*
               
              Completion Date
               
              */}
            <div className="flex flex-col my-5">
              <label htmlFor="email" className="font-bold text-lg">
                Requested Completion Date:
              </label>
              <input
                type="date"
                min={new Date().toISOString().split('T')[0]}
                {...register('dueDate')}
                className="input input-bordered w-full rounded-md dark:bg-slate-700 dark:text-gray-200 mb-2 border-normal"
              />
            </div>

            {/* 
            
            Resource Links 
            
            */}
            <div className="flex flex-col my-5">
              <div className="flex items-end justify-between">
                <label htmlFor="email" className="font-bold text-lg">
                  Resource Links:
                </label>

                <div className="flex items-center justify-between flex-wrap mb-1">
                  <button
                    type="button"
                    className="white-btn py-0 px-2 flex items-center rounded mr-2 border-none"
                    onClick={() => setResourceCount((prev) => prev + 1)}
                  >
                    <Plus />
                  </button>
                  <button
                    type="button"
                    className="white-btn py-0 px-2 flex items-center rounded border-none"
                    onClick={() => setResourceCount((prev) => (prev > 1 ? prev - 1 : prev))}
                    disabled={resourceCount === 1}
                  >
                    <Minus />
                  </button>
                </div>
              </div>

              {[...new Array(resourceCount)].map((num, i) => (
                <input
                  key={i}
                  {...register(`resource_${i}`)}
                  type="url"
                  placeholder="Add a link"
                  className="textarea input-bordered w-full rounded-md dark:bg-slate-700 dark:text-gray-200 mb-2 border-normal"
                />
              ))}
            </div>

            <div className="hidden xl:flex">{renderSubmitButton()}</div>
          </div>

          <div className="w-full max-w-[34rem]">
            {/* 
            
            Images 
            
            */}
            <div className="flex flex-col my-5">
              <label htmlFor="email" className="font-bold text-lg">
                Upload Images:
              </label>

              <div
                className="
                w-full 
                h-[150px] 
                relative 
                grid 
                place-items-center 
                text-center 
                bg-white 
                dark:bg-slate-700 
                rounded-md 
                border-2 
                border-dashed 
                border-normal 
                "
              >
                <input
                  type="file"
                  name="picture"
                  accept=".jpg, .jpeg, .png, .gif, .webp"
                  multiple
                  className="absolute w-full h-full cursor-pointer opacity-0"
                  onChange={handleFileChange}
                />
                <p className="text-gray-500 dark:text-slate-300">
                  Click to Open File Select <br />
                  or <br />
                  Drag and Drop Files Here
                </p>
              </div>

              {Boolean(imageDataUrls.length) && (
                <div className="flex flex-col my-5">
                  <p className="font-bold text-lg flex items-end justify-between">
                    <span>Images:</span>
                    <span
                      className={`text-xs text-gray-500 
                      ${totalImagesKB > maxKB && '!text-red-700'}`}
                    >
                      {`${totalImagesKB.toFixed(0)} KB / ${maxKB.toFixed(0)} KB`}
                    </span>
                  </p>
                  {totalImagesKB > maxKB && (
                    <span className="text-center underline underline-offset-2 my-2">
                      Tip: Use a resource link for large images
                    </span>
                  )}

                  <div className="flex flex-wrap">
                    {imageDataUrls.map((data, i) => (
                      <div key={i} className="w-1/2">
                        <div className="relative group w-fit max-w-full p-1">
                          <button
                            type="button"
                            className="bg-gray-600 p-2 rounded absolute top-4 right-4 hidden group-hover:block z-[1] text-white hover:bg-gray-700"
                            onClick={() => setImageDataUrls([...imageDataUrls.filter((d, idx) => i !== idx)])}
                          >
                            <Garbage />
                          </button>

                          <img
                            src={data}
                            alt={`Uploaded file number ${i}`}
                            className="group-hover:brightness-50 group-hover:bg-gray-50 max-w-full border border-normal"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* 
        
        Mobile Submit Button 
        
        */}
        <div className="xl:hidden">{renderSubmitButton()}</div>
      </form>
    </div>
  )
}

export default AdminIssueForm

import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../../contexts/AuthContext";

const NoToken = () => {
  const { requestResetEmail } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { email } = data;
    const res = await requestResetEmail(email);

    if (res?.success) {
      reset();
      toast.success("Reset Email Sent", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      toast.error("Account Email Not Found", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="px-1.2rem w-full">
      <form className="max-w-screen-sm mx-auto mt-1.6rem" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-control">
          <label className="label">
            <span className="label-text dark:text-gray-200">Your Email</span>
          </label>
          <input
            className="input input-bordered dark:bg-slate-600 dark:text-gray-200"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          {errors.email?.type === "required" && (
            <p className="label text-red-500">This field is required</p>
          )}
          {errors.email?.type === "pattern" && (
            <p className="label text-red-500">Invalid Email Address</p>
          )}
        </div>

        <button className="btn btn-primary w-full mt-0.8rem" type="submit">
          Send Recovery Email
        </button>
      </form>

      <Link className="block mt-1.6rem text-center" to="/login">
        Remember your password? Login here
      </Link>

      <ToastContainer position="bottom-center" autoClose={3000} newestOnTop />
    </div>
  );
};

export default NoToken;

import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import axios from '../../../axios'
import { useAuth } from '../../../contexts/AuthContext'
import UserCard from './UserCard'

export default function UserList({ searchTerm }) {
  const { token } = useAuth()

  const [admins, setAdmins] = useState([])
  const [clients, setClients] = useState([])
  const [superUsers, setSuperUsers] = useState([])

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const { data } = await axios.get('/admin/users', {
          headers: { Authorization: `JWT ${token}` },
        })
        return data
      } catch (err) {
        console.log(err)
      }
    }

    ;(async () => {
      let users = await getAllUsers()
      if (!users) return
      users = users.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? -1 : 1))

      const superAdminUsers = []
      const adminUsers = []
      const clientUsers = []

      users.forEach((user) => {
        switch (user.role) {
          case 'SuperAdmin':
            superAdminUsers.push(user)
            break
          case 'Admin':
            adminUsers.push(user)
            break
          case 'Client':
            clientUsers.push(user)
            break
          default:
            break
        }
      })

      setSuperUsers(superAdminUsers)
      setAdmins(adminUsers)
      setClients(clientUsers)
    })()
  }, [token])

  const renderUserGrid = (title, userList) => {
    return (
      <div className="mb-10">
        <h3 className="font-semibold text-2xl border-b border-normal dark:text-gray-200">{title}</h3>
        <ul
          className="              
              grid 
              grid-cols-1 
              md:grid-cols-2  
              2xl:grid-cols-3 
              gap-2rem 
              lg:gap-3.2rem 
              py-1.2rem"
        >
          {userList.map((user) => (
            <li key={user._id}>
              <UserCard cardUser={user} />
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div>
      {renderUserGrid(
        'Super Admins',
        searchTerm
          ? superUsers.filter((c) => c.email.toLowerCase().includes(searchTerm.toLowerCase().trim()))
          : superUsers
      )}

      {renderUserGrid(
        'Admins',
        searchTerm ? admins.filter((c) => c.email.toLowerCase().includes(searchTerm.toLowerCase().trim())) : admins
      )}
      {renderUserGrid(
        'Clients',
        searchTerm ? clients.filter((c) => c.email.toLowerCase().includes(searchTerm.toLowerCase().trim())) : clients
      )}
    </div>
  )
}

import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useAuth } from '../../contexts/AuthContext'
import { useEffect } from 'react'

export default function Login() {
  const navigate = useNavigate()
  const { login, verifyToken } = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    const { email, password } = data
    const res = await login(email, password)

    if (res?.success) {
      navigate('/dashboard')
    } else {
      toast.error('Invalid Login Credentials', {
        position: 'bottom-center',
        autoClose: 3000,
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      const res = await verifyToken()
      if (res.success) navigate('/dashboard')
    })()
  }, [verifyToken, navigate])

  return (
    <main className=" dark:bg-slate-800 dark:text-gray-200">
      <div className="max-w-lg mx-auto px-1.2rem w-full pt-4rem">
        <h1 className="text-center font-bold uppercase text-4xl">Login</h1>

        <form className="max-w-screen-sm mx-auto mt-1.6rem" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control">
            <label className="label">
              <span className="label-text dark:text-gray-200">Your Email</span>
            </label>

            <input
              className="input input-bordered dark:bg-slate-600 dark:text-gray-200"
              {...register('email', {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.email?.type === 'required' && <p className="label text-red-500">This field is required</p>}
            {errors.email?.type === 'pattern' && <p className="label text-red-500">Invalid Email Address</p>}
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text dark:text-gray-200">Your Password</span>
            </label>

            <input
              className="input input-bordered dark:bg-slate-600 dark:text-gray-200"
              type="password"
              {...register('password', { required: true })}
            />
            {errors.password?.type === 'required' && <p className="label text-red-500">This field is required</p>}
          </div>

          <button className="btn btn-primary w-full mt-0.8rem" type="submit">
            Login
          </button>
        </form>

        <Link className="block mt-1.6rem text-center" to="/password/reset">
          Forgot password?
        </Link>
      </div>

      <ToastContainer position="bottom-center" autoClose={3000} newestOnTop />
    </main>
  )
}

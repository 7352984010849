import React from 'react'
import dayjs from 'dayjs'

import { useTheme } from '../../../../hooks/useTheme'
import { Filters, FilterColors } from '..'
import Gantt from '../../../Shared/Gantt'

const ProjectGantt = ({ project }) => {
  const { theme } = useTheme()

  const issueData = project.issues.nodes
    .filter(
      (issue) =>
        issue.state.name !== 'Backlog' &&
        issue.state.name !== 'Triage' &&
        dayjs(issue.completedAt || dayjs()).isAfter(dayjs().subtract(90, 'd'))
    ) // Filter out backlog and triage issues and issues older than 90 days
    .map((issue) => {
      const { createdAt, startedAt, dueDate, completedAt } = issue
      const issueState = issue.state.name
      const isTodo = issueState === 'Todo'
      const isInProgress = issueState === 'In Progress'
      const isTodoAndNotDue = isTodo && !dueDate

      // startedAt | (isTodo ? today : createdAt)
      const startDate = dayjs(startedAt || (isTodo ? dayjs() : createdAt)).format('YYYY-M-D')
      // completedAt | dueDate | (isTodo ? today + 7 : today)
      const endDate = dayjs(
        completedAt || dueDate || (!completedAt ? dayjs().add(isTodo ? 7 : 3, 'd') : dayjs())
      ).format('YYYY-M-D')

      const startLabel = isTodoAndNotDue ? '' : dayjs(startDate).format('MMM D')
      const endLabel = isInProgress
        ? dueDate
          ? dayjs(dueDate).format('MMM DD')
          : 'Ongoing'
        : isTodoAndNotDue
        ? ''
        : dayjs(endDate).format('MMM D')

      const barColor =
        !dueDate && !completedAt
          ? `linear-gradient(90deg, ${FilterColors[issueState]} 0%, transparent 100%)`
          : FilterColors[issueState]
      const arrowColor = FilterColors[issueState]
      const barLabelColor = isInProgress || (theme === 'light' && isTodo) ? 'rgba(0,0,0,.8)' : 'white'

      return {
        id: issue.id,
        title: issue.title,
        startDate,
        endDate,
        barColor,
        arrowColor,
        barLabel: issue.title,
        barLabelColor,
        startLabel,
        endLabel,
        parentId: issue.parent?.id,
        issueState,
        completedAt,
        dueDate,

        itemChildrenCallback: (item, children) => {
          const minChildsStartDate = dayjs.min(children.map((child) => dayjs(child.startDate)))
          const maxChildsEndDate = dayjs.max(children.map((child) => dayjs(child.endDate)))

          const minDate = dayjs.min(dayjs(item.startDate), dayjs(minChildsStartDate))
          const maxDate = dayjs.max(dayjs(item.endDate), dayjs(maxChildsEndDate))

          item.startDate = minDate
          item.endDate = maxDate
          item.startLabel = minDate.format('MMM D')
          item.endLabel = maxDate.format('MMM D')

          const shouldChangeColor = children.some(
            ({ issueState }) => issueState === 'In Progress' || issueState === 'In Review'
          )
          if (shouldChangeColor) {
            // If an issue has not been completed or no due date has been assigned, make the bar a gradient
            item.barColor =
              !item.dueDate && !item.completedAt
                ? `linear-gradient(90deg, ${FilterColors['In Progress']} 0%, transparent 100%)`
                : FilterColors['In Progress']
            item.barLabelColor = 'rgba(0,0,0,.8)'
          }
          return item
        },
      }
    })
    .sort((a, b) => (dayjs(a.endDate).isBefore(b.endDate) ? -1 : 1)) // Sort by most recent endDate

  const legend = Filters.filter((state) => state !== 'Backlog').map((filter) => ({
    label: filter,
    color: FilterColors[filter],
  }))

  return (
    <Gantt
      items={issueData}
      defaultZoom={25}
      chartHeight="70vh"
      chartTitle="Project Tasks"
      legend={legend}
      forceParentsToWrapChildren={true}
    />
  )
}

export default ProjectGantt

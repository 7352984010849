import { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { useWindowSize } from '../../hooks/useWindowResize'
import { useAuth } from '../../contexts/AuthContext'
import UserProjects from './UserProjects'

import Ghost from '../../SVG/Ghost'
import Settings from '../../SVG/Settings'
import ExitRight from '../../SVG/ExitRight'
import GraduateCap from '../../SVG/GraduateCap'
import Lightning from '../../SVG/Lightning'

export default function Sidebar({ sidebarOpen, setSideBarOpen }) {
  const location = useLocation()
  const [width] = useWindowSize()
  const { logout, isAdmin } = useAuth()

  // Fixed body when open
  useEffect(() => {
    sidebarOpen
      ? document.documentElement.classList.add(`!overflow-hidden`)
      : document.documentElement.classList.remove(`!overflow-hidden`)
    return () => document.documentElement.classList.remove(`overflow-hidden`)
  }, [sidebarOpen])

  // Close on resize
  useEffect(() => {
    if (width >= 1024) setSideBarOpen(false)
  }, [width, setSideBarOpen])

  // Close on page change
  useEffect(() => {
    setSideBarOpen(false)
  }, [location.pathname, setSideBarOpen])

  const renderLink = ({ title, route, icon }) => {
    return (
      <li className="w-full flex flex-col">
        <NavLink
          to={route}
          className={`
            flex 
            items-center 
            px-4 
            py-3 
            hover:bg-gray-200 
            active:bg-gray-300 
            dark:hover:bg-gray-800 
            dark:active:bg-gray-900 
            dark:text-gray-200 
          ${location.pathname === route && '!bg-primary !text-white'}`}
        >
          {icon}
          <span className="ml-2">{title}</span>
        </NavLink>
      </li>
    )
  }

  return (
    <>
      {/* Mobile Backdrop */}
      <div
        className={`fixed top-[85px] h-[calc(100vh-85px)] w-full bg-black z-[999] transition-all lg:hidden ${
          sidebarOpen ? 'opacity-30' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setSideBarOpen(!sidebarOpen)}
      ></div>

      {/* Sidebar */}
      <div
        className={`
          fixed
          top-[85px] 
          flex 
          flex-col 
          z-[999] 
          w-[260px] 
          h-[calc(100vh-85px)] 
          bg-white transition-[margin-left] 
          shadow-lg 
          lg:sticky 
          dark:bg-slate-700
          border-r
          border-dark
        ${sidebarOpen ? 'ml-0' : '-ml-[260px] lg:ml-0'}`}
      >
        {renderLink({ title: 'All Projects', route: '/dashboard', icon: <Lightning /> })}

        <UserProjects />

        {isAdmin && renderLink({ title: 'Users', route: '/dashboard/users', icon: <Ghost /> })}
        {renderLink({ title: 'Learn', route: '/dashboard/learn', icon: <GraduateCap /> })}
        {renderLink({ title: 'Settings', route: `/dashboard/settings`, icon: <Settings /> })}

        <div className="py-2 border-t border-dark">
          <button
            onClick={logout}
            className="
            nav-link 
            w-full flex 
            items-center 
            px-1.2rem 
            py-3 
            hover:bg-gray-200 
            active:bg-gray-300 
            dark:hover:bg-gray-800 
            dark:active:bg-gray-900 
            dark:text-gray-200 "
          >
            <ExitRight />
            <span className="ml-2">Log Out</span>
          </button>
        </div>
      </div>
    </>
  )
}

import axios from "axios";

let baseURL = "";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  baseURL = "http://localhost:4200";
} else {
  // production code
  baseURL = "https://electriq.app/api";
}

const instance = axios.create({ baseURL });

export default instance;

import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { useAuth } from '../../../contexts/AuthContext'

import Pencil from '../../../SVG/Pencil'
import ExitLeft from '../../../SVG/ExitLeft'
import Plus from '../../../SVG/Plus'
import Minus from '../../../SVG/Minus'
import List from '../../../SVG/List'
import Grid from '../../../SVG/Grid'

import ProjectsGantt from './ProjectsGantt'
import ProjectGrid from './ProjectsGrid'
import ProjectList from './ProjectsList'

function ProjectsView({
  title,
  projects,
  defaultCollapsed = false,
  collapsable = false,
  searchable = false,
  showGantt = false,
  userData,
  setUserData,
}) {
  const { user, isAdmin, joinProject, leaveProject } = useAuth()

  const [collapsed, setCollapsed] = useState(defaultCollapsed)
  const [searchTerm, setSearchTerm] = useState('')
  const [viewType, setViewType] = useState('grid') // 'list' | 'grid'
  const [isEditing, setIsEditing] = useState(false)

  const handleJoinProject = async (project) => {
    const res = userData ? await joinProject(project.id, userData._id) : await joinProject(project.id)
    handleApiResponse(res)
  }

  const handleLeaveProject = async (project) => {
    const res = userData ? await leaveProject(project.id, userData._id) : await leaveProject(project.id)
    handleApiResponse(res)
  }

  const handleApiResponse = (res) => {
    if (res?.success) {
      setUserData && setUserData(res.updatedUser)
      toast.success(res?.message, {
        position: 'top-right',
        autoClose: 1500,
      })
    } else {
      toast.error(res?.message, {
        position: 'top-right',
        autoClose: 1500,
      })
    }
  }

  const canEditProjects = () => {
    if (!isAdmin) return false
    if (!userData) return true
    if (user.role === 'SuperAdmin') return true
    if (userData._id === user._id) return true
  }

  const shownProjects = projects.filter(
    (p) =>
      p.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      p.teams.some((t) => t.toLowerCase().includes(searchTerm.toLowerCase()))
  )

  const joinedProjectsMap = useMemo(() => {
    const projectMap = new Map()
    ;(userData || user).projects.forEach((projectId) => projectMap.set(projectId, true))
    return projectMap
  }, [user, userData])

  return (
    <div>
      {/* Top Bar */}
      <div className="my-4 flex items-center justify-between border-b border-normal pb-4">
        <h1 className="text-2xl sm:text-4xl font-bold dark:text-gray-100">
          {title} <span className="text-lg ">({projects.length})</span>
        </h1>

        <div className="flex items-center">
          {/* Search Input */}
          {searchable && !collapsed && (
            <div className="hidden sm:flex justify-end">
              <input
                type="text"
                name="Project Search"
                placeholder="Search Projects..."
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
                className="input input-bordered border-dark rounded w-[260px] h-auto py-1 dark:bg-slate-700 dark:text-gray-200"
              />
            </div>
          )}

          {/* Collapse Button */}
          {collapsable && (
            <button
              className="tooltip grid place-items-center z-50 white-btn p-[.35rem] rounded ml-4"
              data-tip={collapsed ? 'Show' : 'Hide'}
              onClick={collapsed ? () => setCollapsed(false) : () => setCollapsed(true)}
            >
              {collapsed ? <Plus /> : <Minus />}
            </button>
          )}
        </div>
      </div>

      {/* Collapsable Section */}
      {!collapsed && (
        <>
          {showGantt && Boolean(shownProjects.length) && <ProjectsGantt projects={shownProjects} />}

          {Boolean(shownProjects.length) && (
            <div className="flex justify-between items-end mb-4 mt-6 dark:text-gray-200">
              {/* Grid & List Buttons */}
              <div className="flex items-stretch rounded overflow-hidden border border-dark bg-gray-50 dark:bg-slate-800">
                <button
                  onClick={() => setViewType('grid')}
                  className={`white-btn rounded-sm border-none p-1 ${
                    viewType !== 'grid' && '!bg-gray-50 text-gray-500 dark:!bg-slate-800 dark:text-gray-400'
                  }`}
                >
                  <Grid height={16} width={16} />
                </button>
                <button
                  onClick={() => setViewType('list')}
                  className={`white-btn rounded-sm border-none p-1 ${
                    viewType !== 'list' && '!bg-gray-50 text-gray-500 dark:!bg-slate-800 dark:text-gray-400'
                  }`}
                >
                  <List />
                </button>
              </div>

              <p>{isEditing && 'You are in edit mode'}</p>

              {/* Edit Button */}
              {canEditProjects() && (
                <button className="white-btn rounded py-1 px-2" onClick={() => setIsEditing(!isEditing)}>
                  <span className="flex items-center">
                    {isEditing ? (
                      <>
                        <span className="mr-2">Exit Edit</span> <ExitLeft height={16} />
                      </>
                    ) : (
                      <>
                        <span className="mr-2">Edit Projects</span> <Pencil height={16} />
                      </>
                    )}
                  </span>
                </button>
              )}
            </div>
          )}

          {shownProjects.length ? (
            <>
              {/* Project Grid */}
              {viewType === 'grid' && (
                <ProjectGrid
                  projects={shownProjects}
                  joinedProjectsMap={joinedProjectsMap}
                  joinProject={handleJoinProject}
                  leaveProject={handleLeaveProject}
                  isEditing={isEditing}
                />
              )}
              {/* Project List */}
              {viewType === 'list' && (
                <ProjectList
                  projects={shownProjects}
                  joinedProjectsMap={joinedProjectsMap}
                  joinProject={handleJoinProject}
                  leaveProject={handleLeaveProject}
                  isEditing={isEditing}
                />
              )}
            </>
          ) : (
            <p className="pl-1.2rem font-semibold text-xl dark:text-white">
              {searchTerm ? `No projects match "${searchTerm}"` : 'No projects'}
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default ProjectsView

import { FilterColors } from '..'
import IssueCard from './IssueCard'

export default function Column({ type, issues }) {
  return (
    <div className="flex flex-col flex-shrink-0 w-[17rem] ml-5 last:mr-5">
      <div
        style={{ border: `2px solid ${FilterColors[type]}` }}
        className="
            flex 
            items-center 
            justify-center
            flex-shrink-0
            rounded-full
            py-2
            px-2
            bg-white
            dark:text-white
            dark:bg-slate-700
          "
      >
        <span className="block">{type}</span>
        <span className=" ml-2">({issues.length})</span>
      </div>

      <ul className="flex flex-1 flex-col pl-3 pr-[6px] pb-2 overflow-x-hidden overflow-y-scroll max-h-auto thin-scrollbar">
        {issues.map((issue) => (
          <li className="bg-white mt-3 p-3 rounded dark:bg-slate-600" key={issue.id}>
            <IssueCard issue={issue} />
          </li>
        ))}
      </ul>
    </div>
  )
}

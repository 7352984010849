import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useAuth } from '../../../contexts/AuthContext'

const ResetPassword = () => {
  const { updatePassword } = useAuth()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm()

  const watchPassword = watch('password')

  const onSubmit = async (data) => {
    const { password } = data

    const res = await updatePassword(password)
    if (res?.success) {
      reset()
      toast.success(res?.message)
    } else {
      res?.errors?.map((error) => toast.error(error.msg))
    }
  }

  return (
    <div className="w-full pt-2rem max-w-screen-sm mx-auto">
      <h3 className="lg:text-center font-bold uppercase text-2xl dark:text-gray-200">Update Password</h3>

      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        {/* Password */}
        <div className="form-control">
          <label className="label">
            <span className="label-text dark:text-gray-200">New Password</span>
          </label>

          <input
            className="input input-bordered rounded-md dark:bg-slate-600 dark:text-gray-200 border-normal"
            type="password"
            {...register('password', { required: true, minLength: 6 })}
          />

          {errors.password?.type === 'required' && <p className="label text-red-500">This field is required</p>}
          {errors.password?.type === 'minLength' && (
            <p className="label text-red-500">Password should be at least 6 characters</p>
          )}
        </div>

        {/* Confirm Password */}
        <div className="form-control">
          <label className="label">
            <span className="label-text dark:text-gray-200">Confirm Password</span>
          </label>
          <input
            className="input input-bordered rounded-md dark:bg-slate-600 dark:text-gray-200 border-normal"
            type="password"
            {...register('confirmPW', {
              required: true,
              minLength: 6,
              validate: (val) => val === watchPassword,
            })}
          />
          {errors.confirmPW?.type === 'required' && <p className="label text-red-500">This field is required</p>}
          {errors.confirmPW?.type === 'minLength' && (
            <p className="label text-red-500">Password should be at least 6 characters</p>
          )}
          {errors.confirmPW?.type === 'validate' && <p className="label text-red-500">Passwords don't match</p>}
        </div>

        <button className="btn btn-primary w-full mt-0.8rem" type="submit">
          Update
        </button>
      </form>
    </div>
  )
}

export default ResetPassword

import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { ProjectStateColors, ProjectStateIcons } from '.'
import { useAuth } from '../../../contexts/AuthContext'

import LinearIcon from '../../../SVG/LinearIcon'
import Plus from '../../../SVG/Plus'
import Minus from '../../../SVG/Minus'

const ProjectsList = ({ projects, joinedProjectsMap, joinProject, leaveProject, isEditing }) => {
  const { isAdmin } = useAuth()

  return (
    <div className="dark:text-white">
      {/* Column Headers */}
      <div className="flex items-center w-full mb-1 dark:text-gray-400 text-sm px-2">
        <span className={`overflow-hidden transition-all ${isEditing ? 'flex-[.05] mr-2' : 'flex-[0]'}`}>Edit</span>
        <span className="flex-1">Title</span>
        <span className="flex-[.15] text-center ml-2 min-w-[90px]">Status</span>
        <span className="flex-[.1] text-center ml-2 min-w-[80px]">End Date</span>
        {isAdmin && <span className="flex-[.1] text-center ml-2">Link</span>}
      </div>

      <div className="flex flex-col">
        {projects.map((project) => (
          <div
            key={project.id}
            className="
              flex 
              items-center 
              py-3 
              px-2 
              bg-white
              dark:bg-slate-700
              hover:bg-gray-100
              dark:hover:bg-slate-600 
              border-b 
              first:border-t 
              border-x 
              first:rounded-t-md 
              last:rounded-b-md 
              border-dark"
          >
            <span className={`overflow-hidden transition-all ${isEditing ? 'flex-[.05] mr-2' : 'flex-[0]'}`}>
              <button
                onClick={() => (joinedProjectsMap.get(project.id) ? leaveProject(project) : joinProject(project))}
                className={`white-btn mx-auto p-1 ${
                  joinedProjectsMap.get(project.id)
                    ? 'bg-red-100 hover:!bg-red-400 dark:bg-red-200 dark:text-black'
                    : 'bg-emerald-50 hover:!bg-emerald-400 dark:bg-emerald-100 dark:text-black'
                }`}
                disabled={!isEditing}
              >
                {joinedProjectsMap.get(project.id) ? <Minus height={18} width={18} /> : <Plus height={18} width={18} />}
              </button>
            </span>

            <span className="flex-1 truncate">
              <Link to={`/dashboard/projects/${project.id}`} className="hover:underline">
                {project.name}
              </Link>
            </span>

            <span className="capitalize text-sm flex items-center justify-center flex-[.15] ml-2 min-w-[90px]">
              <span className="mr-1" style={{ color: ProjectStateColors[project.state] }}>
                {ProjectStateIcons[project.state]}
              </span>
              {project.state}
            </span>

            <div className="flex items-center justify-between my-2 flex-[.1] min-w-[80px] ml-2">
              <span className="text-sm mx-auto">
                {project.targetDate ? dayjs(project.targetDate).format('M/D/YY') : 'TBD'}
              </span>
            </div>

            {isAdmin && (
              <span className="flex-[.1] ml-2">
                <a
                  href={project.url}
                  rel="noreferrer"
                  target="_blank"
                  className="bg-gradient-to-b from-[#6069CA] to-[#2B359C] rounded-md p-2 block w-fit mx-auto"
                >
                  <LinearIcon width={16} height={16} />
                </a>
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjectsList

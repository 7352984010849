import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import Modal from '../../Shared/Modal'

const VideoGuides = [
  {
    title: 'Navigating the Sidebar & Settings',
    link: 'https://www.loom.com/embed/ffbf0d589e6645628093aa0a848def83',
    summary:
      'Learn how projects are listed, what you can discover in the learn page, and what is available in the settings page',
  },
  {
    title: 'Navigating Projects',
    link: 'https://www.loom.com/embed/3722bc371437400392102c2a31a915f4',
    summary:
      'A quick showcase of how to view the progress of projects and how to add your input directly into the project',
  },
  {
    title: 'Submitting a Task Request',
    link: 'https://www.loom.com/embed/fcbe01851fa04d1da3b0bd16ef93d235',
    summary:
      'Learn how projects are listed, what you can discover in the learn page, and what is available in the settings page',
  },
]

const GettingStarted = () => {
  const { user, updatePreferences } = useAuth()

  const shouldShowModal = user?.preferences?.showGettingStartedModal || false

  const [showModal, setShowModal] = useState(shouldShowModal)
  const [guideStep, setGuideStep] = useState(0)

  const updateDoNotShow = () => updatePreferences('showGettingStartedModal', false)

  const closeModal = () => {
    if (guideStep === VideoGuides.length - 1) updateDoNotShow()
    setShowModal(false)
  }

  const handleDontShowAgain = () => {
    updateDoNotShow()
    setShowModal(false)
  }

  return (
    <>
      {showModal && (
        <Modal closeModal={closeModal}>
          <div className="w-full md:w-[40rem] lg:w-[55rem] text-center p-5 dark:text-white max-h-[95vh] overflow-y-scroll thin-scrollbar">
            <div className="mb-10 border-b border-normal">
              <h1 className="font-semibold text-4xl dark:text-gray-200">Getting Started</h1>
              <p className="py-2 dark:text-gray-200 text-lg">
                Welcome, watch the videos below to learn how the Electriq App works
              </p>
            </div>

            {/* Videos */}
            <div className="relative">
              {VideoGuides.map((video, i) => (
                <div key={video.link} className={`w-full flex flex-col ${guideStep !== i && 'hidden'}`}>
                  <iframe
                    title={video.title}
                    src={guideStep === i ? video.link : ''}
                    frameBorder="0"
                    allowscriptaccess="always"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    className="h-full w-full rounded aspect-video"
                  />

                  <div className="mt-4">
                    <h2 className="text-xl">{video.title}</h2>
                    <p className="mt-1 text-sm font-light tracking-wide dark:text-gray-300">{video.summary}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Steps */}
            <div className="flex items-center mx-auto w-fit py-8">
              {[...Array(VideoGuides.length)].map((_, i) => (
                <div className="flex items-center" key={i}>
                  <button
                    className={`h-12 w-12 grid place-items-center text-xl font-bold rounded-full bg-gray-300 dark:bg-slate-700 transition
                    ${guideStep >= i && '!bg-primary text-white'}`}
                    onClick={() => setGuideStep(i)}
                  >
                    {i + 1}
                  </button>
                  {i + 1 !== VideoGuides.length && (
                    <span
                      className={`h-2 w-14 z-10 block bg-gray-300 dark:bg-slate-700 transition ${
                        guideStep > i && '!bg-primary'
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>

            <div className="flex items-center justify-end">
              <button className="white-btn py-2 flex items-center text-sm" onClick={handleDontShowAgain}>
                Don't show again
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default GettingStarted

import React from "react";
import dayjs from "dayjs";

import Gantt from "../../Shared/Gantt";
import { ProjectStateColors } from ".";

const ProjectsGantt = ({ projects }) => {
  const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const legend = Object.entries(ProjectStateColors).map((entry) => ({ label: capitalize(entry[0]), color: entry[1] }));

  const getProjectDateRange = (project) => {
    const startDate = dayjs(project.startDate || project.createdAt || dayjs()).format("YYYY-MM-DD");
    const endDate = dayjs(
      project.targetDate || (project.state === "started" ? dayjs() : project.issues.nodes[0]?.createdAt)
    ).format("YYYY-MM-DD");

    return { startDate, endDate };
  };

  const data = projects.map((project) => {
    const { startDate, endDate } = getProjectDateRange(project);

    const startLabel = dayjs(startDate).format("MMM D");
    const endLabel =
      project.state === "started"
        ? project.targetDate
          ? "Ends " + dayjs(project.targetDate).format("MMM D")
          : "Ongoing"
        : dayjs(endDate).format("MMM D");

    return {
      id: project.id,
      title: project.name,
      startDate,
      endDate,
      barColor: ProjectStateColors[project.state],
      startLabel,
      endLabel,
    };
  });

  return <Gantt items={data} chartTitle="Projects Timeline" legend={legend} />;
};

export default ProjectsGantt;

import React, { useRef, useEffect, useCallback, useMemo, useId, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'

import ItemTitle from './ItemTitle'
import ItemBar from './ItemBar'
import DateBar from './DateBar'

import { useScrollSync } from '../../../hooks/useScrollSync'

import LeftArrow from '../../../SVG/LeftArrow'
import RightArrow from '../../../SVG/RightArrow'
import './styles.css'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const RowHeight = 40
const CollapsedRowHeight = 4

/**

  items: item[]

  item: {
    id: string,
    title: string,
    startDate: Dayjs | string,
    endDate: Dayjs | string,
    barColor: string,
    arrowColor: string,
    barLabel: string,
    barLabelColor: string,
    startLabel: string,
    endLabel: string,
    parentId: string,
    itemChildrenCallback: (children) => item
  }

 */

const Gantt = ({ items = [], defaultZoom = 10, chartHeight = '70vh', chartTitle = 'Timeline', legend = [] }) => {
  const [dateWidth, setDateWidth] = useState(defaultZoom)
  const [collapsedItemIds, setCollapsedItemIds] = useState(new Map()) // { [itemId]: boolean }

  const id = useId()
  const location = useLocation()

  const resizer = useRef(null)
  const leftSide = useRef(null)
  const rightSide = useRef(null)
  const mouseX = useRef(0)
  const mouseY = useRef(0)
  const rightWidth = useRef(0)

  const dateBar = useScrollSync(id)
  const barsContainer = useScrollSync(id)

  const collapseAllItems = () => {
    setCollapsedItemIds(new Map(itemChildrenMap.get('root')?.map((child) => [child.id, true])))
  }

  const handleZoomRangeMove = ({ target }) => {
    if (!dateBar.current) return

    const oldVal = dateWidth
    const newVal = target.value
    const scrollLeft = dateBar.current.scrollLeft

    newVal > oldVal
      ? (dateBar.current.scrollLeft = scrollLeft + parseInt(target.value))
      : (dateBar.current.scrollLeft = scrollLeft - parseInt(target.value))

    setDateWidth(target.value)
  }

  const collapseTitlesColumn = () => {
    rightSide.current.style.transition = 'width 250ms ease-out'
    rightSide.current.style.width = `${95}%`
    setTimeout(() => (rightSide.current.style.transition = 'none'), 250)
  }

  const openTitlesColumn = (e) => {
    e.stopPropagation()
    rightSide.current.style.transition = 'width 250ms ease-out'
    rightSide.current.style.width = '60%'
    setTimeout(() => (rightSide.current.style.transition = 'none'), 250)
  }

  const onResizerMouseDown = function (e) {
    mouseX.current = e.clientX
    mouseY.current = e.clientY
    rightWidth.current = rightSide.current.getBoundingClientRect().width

    document.addEventListener('mousemove', onResizerMouseMove)
    document.addEventListener('mouseup', onResizerMouseUp)
  }

  const onResizerMouseMove = function (e) {
    const dx = e.clientX - mouseX.current

    const newRightWidth = ((rightWidth.current - dx) * 100) / resizer.current.parentNode.getBoundingClientRect().width
    rightSide.current.style.width = `${newRightWidth}%`

    document.documentElement.style.cursor = 'col-resize'

    leftSide.current.style.userSelect = 'none'
    leftSide.current.style.pointerEvents = 'none'
    rightSide.current.style.userSelect = 'none'
    rightSide.current.style.pointerEvents = 'none'
  }

  const onResizerMouseUp = function () {
    document.documentElement.style.removeProperty('cursor')

    leftSide.current.style.removeProperty('user-select')
    leftSide.current.style.removeProperty('pointer-events')
    rightSide.current.style.removeProperty('user-select')
    rightSide.current.style.removeProperty('pointer-events')

    // Remove the handlers of mousemove and mouseup
    document.removeEventListener('mousemove', onResizerMouseMove)
    document.removeEventListener('mouseup', onResizerMouseUp)
  }

  /**
   * @param {string} id
   */
  const handleRowMouseOver = (id) => {
    const bars = barsContainer.current.querySelectorAll('.gantt-bar')
    const titles = leftSide.current.querySelectorAll('.gantt-title')
    // Highlight background of bar and title of row with given id
    bars.forEach((bar, i) => {
      bar.dataset.itemId === id
        ? bar.classList.add('bg-gray-200', 'dark:bg-slate-800')
        : bar.classList.remove('bg-gray-200', 'dark:bg-slate-800')
      titles[i].dataset.itemId === id
        ? titles[i].classList.add('bg-gray-200', 'dark:bg-slate-800')
        : titles[i].classList.remove('bg-gray-200', 'dark:bg-slate-800')
    })
  }

  /**
   * @param {Date | string} startDate
   * @param {Date | string} endDate
   * @returns Number of days between start date and end date
   */
  const getDayDiff = (startDate, endDate) => Math.abs(dayjs(startDate).diff(endDate, 'days'))

  /**
   * @returns Min and Max dates from items +/- 7 days
   */
  const getItemsDateRange = useMemo(() => {
    const startDates = []
    const endDates = []
    items.forEach((item) => {
      const { startDate, endDate } = item
      startDates.push(dayjs(startDate))
      endDates.push(dayjs(endDate))
    })

    // max and min day +/- 7 days
    const firstDate = dayjs.min(startDates.filter((date) => date.isValid()))?.subtract(7, 'day')
    const lastDate = dayjs.max(endDates.filter((date) => date.isValid()))?.add(7, 'day')

    return { firstDate, lastDate }
  }, [items])

  /**
   * @returns The number of days currently shown on the chart
   */
  const getNumDaysShown = () => {
    const { firstDate, lastDate } = getItemsDateRange
    const daysBetween = getDayDiff(firstDate, lastDate)
    return daysBetween + 1 // +1 to include last date
  }

  /**
   * @returns Map[item.id | 'root']: item[]
   * - The children for any given item (passing 'root' returns all tasks without a parent)
   */
  const itemChildrenMap = useMemo(() => {
    const childrenMap = new Map()
    items.forEach((item) =>
      childrenMap.set(item.parentId || 'root', [...(childrenMap.get(item.parentId || 'root') || []), item])
    )
    return childrenMap
  }, [items])

  // Boolean to show collapse and expand all buttons
  const hasDropdowns = itemChildrenMap.get('root')?.length !== items.length

  /**
   * @returns Map[item.id]: parentId | undefined
   */
  const itemParentMap = useMemo(() => {
    const itemGraph = new Map()
    items.forEach((item) => itemGraph.set(item.id, item.parentId))
    return itemGraph
  }, [items])

  /**
   * @param item
   * @returns Object containing: { isParentCollapsed: boolean, collapsedParentId: string }
   */
  const isItemParentCollapsed = useCallback(
    (item) => {
      let isParentCollapsed = false
      let currItemId = item.parentId
      while (currItemId && !isParentCollapsed) {
        isParentCollapsed = collapsedItemIds.get(currItemId)
        if (!isParentCollapsed) currItemId = itemParentMap.get(currItemId)
      }
      return { isParentCollapsed, collapsedParentId: currItemId }
    },
    [collapsedItemIds, itemParentMap]
  )

  // Callback function for editing items based on childrens' values
  ;(() => {
    items = items.map((item) => {
      if (!itemChildrenMap.get(item.id) || !item.itemChildrenCallback) return item
      const children = itemChildrenMap.get(item.id)
      return item.itemChildrenCallback(item, children)
    })
  })()

  /**
   * @param {Date | string} targetDate
   * @param {boolean} onMountScroll
   */
  const scrollToDate = useCallback(
    (targetDate, onMountScroll = false) => {
      const currDayDiv = document.getElementById(id + '-' + dayjs(targetDate).format('YYYY-MMM-D'))
      if (!currDayDiv) return

      const colWidth = currDayDiv.offsetWidth
      const containerWidth = dateBar.current.offsetWidth
      const centerOfCurrDayDiv = currDayDiv.offsetLeft - containerWidth / 2 + colWidth / 2

      dateBar.current.scrollTo({
        left: centerOfCurrDayDiv,
        behavior: onMountScroll ? 'auto' : 'smooth',
      })
    },
    [dateBar, id]
  )

  useEffect(() => {
    scrollToDate(dayjs(), true)
  }, [scrollToDate, location.pathname])

  /**
   *
   * @param {Date | string} date
   * @param {{ barWidth: number, highlight: boolean }} options
   * @returns Renders a vertical bar within the bar list
   */
  const renderVerticalBarOnDate = (date, { barWidth, highlight }) => {
    const { firstDate } = getItemsDateRange
    const offsetDaysStart = getDayDiff(firstDate, dayjs(date))
    const offsetLeft = offsetDaysStart * dateWidth + dateWidth / 2 - barWidth / 2
    return (
      <div
        className={`h-full absolute ${highlight ? 'bg-[#3350E8] z-10' : 'bg-gray-300 dark:bg-slate-800 opacity-50'}`}
        style={{ width: `${barWidth}px`, left: `${offsetLeft}px` }}
        key={dayjs(date).format('YYYY-MM-D') + '_vertical-bar__chart-id__' + id}
      />
    )
  }

  const renderMonthlyBars = () => {
    const numDaysShown = getNumDaysShown()
    const { lastDate } = getItemsDateRange
    const firsts = []
    Array(numDaysShown)
      .fill()
      .forEach((_, i) => {
        const date = dayjs(lastDate).subtract(i, 'day')
        return date.format('D') === '1' && firsts.push(date)
      })

    return firsts.map((date) => renderVerticalBarOnDate(date, { barWidth: 1, highlight: false }))
  }

  const ItemTitleProps = {
    CollapsedRowHeight,
    RowHeight,
    itemChildrenMap,
    collapsedItemIds,
    setCollapsedItemIds,
    isItemParentCollapsed,
    scrollToDate,
    handleRowMouseOver,
  }

  const ItemBarProps = {
    CollapsedRowHeight,
    RowHeight,
    dateWidth,
    itemChildrenMap,
    getItemsDateRange,
    getDayDiff,
    getNumDaysShown,
    isItemParentCollapsed,
    scrollToDate,
    handleRowMouseOver,
  }

  const DateBarProps = { chartId: id, dateWidth, getItemsDateRange, getNumDaysShown, scrollToDate }

  return (
    <div className="gantt-chart dark:text-white">
      {/* Header */}
      <div className="flex justify-between items-center flex-wrap mb-4">
        <div className="flex items-center">
          <h3 className="text-lg font-bold min-w-max">{chartTitle}</h3>

          {hasDropdowns && (
            <>
              <span className="mx-4">•</span>
              <button className="white-btn py-1 px-2 text-xs" onClick={collapseAllItems}>
                Collapse All
              </button>
              <button className="white-btn py-1 px-2 ml-2 text-xs" onClick={() => setCollapsedItemIds(new Map())}>
                Expand All
              </button>
            </>
          )}
        </div>

        {/* Zoom */}
        <label className="flex items-center">
          <span className="mr-1 text-sm">Zoom:</span>
          <input
            type="range"
            min="8"
            max="40"
            value={dateWidth}
            onChange={handleZoomRangeMove}
            step="1"
            className="w-[150px] block appearance-none bg-[#ccc] h-[5px] rounded-[5px] mx-auto outline-none"
          />
        </label>
      </div>

      {/* Chart */}
      <ScrollContainer
        hideScrollbars={false}
        style={{ maxHeight: chartHeight }}
        className="bg-gray-50 border border-dark dark:bg-slate-700 rounded-md thin-scrollbar"
      >
        <div className="flex items-stretch">
          {/* Left Side */}
          <div ref={leftSide} className="flex-1 max-w-full min-w-[70px]">
            <div className="p-2 h-12 flex items-center justify-between border-b-2 border-dark sticky top-0 z-20 overflow-visible bg-gray-50 dark:bg-slate-700">
              <button className="white-btn py-1 px-2 text-sm rounded" onClick={() => scrollToDate(dayjs(), false)}>
                Today
              </button>

              {/* Close Titles Btn */}
              <button
                className="white-btn p-1 text-gray-400 dark:text-slate-400 overflow-hidden ml-3 min-w-max border-normal"
                onClick={collapseTitlesColumn}
              >
                <LeftArrow height={12} width={12} />
              </button>
            </div>

            {/* Titles */}
            <div className="overflow-hidden">
              {itemChildrenMap.get('root')?.map((item, i) => (
                <ItemTitle key={item.id + '_title'} item={item} idx={i} {...ItemTitleProps} />
              ))}
            </div>
          </div>

          {/* Resizer */}
          <div
            ref={resizer}
            onMouseDown={onResizerMouseDown}
            className="cursor-col-resize min-w-[2px] bg-slate-400 dark:bg-slate-900 z-40"
          />

          {/* Right Side */}
          <div ref={rightSide} className="w-[75%] max-w-[calc(100%-75px)] relative">
            {/* Open Titles Btn */}
            <div className="sticky z-40 left-0 top-[11px] h-0">
              <button
                className="white-btn p-1 ml-2 text-gray-400 dark:text-slate-400 border-normal"
                onClick={openTitlesColumn}
              >
                <RightArrow height={12} width={12} />
              </button>
            </div>

            {/* Date Bar */}
            <ScrollContainer
              innerRef={dateBar}
              className="h-12 sticky top-0 bg-gray-50 dark:bg-slate-700 border-b-2 border-dark z-20"
            >
              <div className="h-full items-end inline-flex">
                <DateBar {...DateBarProps} />
              </div>
            </ScrollContainer>

            {/* Bars */}
            <ScrollContainer
              innerRef={barsContainer}
              hideScrollbars={false}
              className="flex flex-col relative thin-scrollbar"
            >
              {renderMonthlyBars()}
              {renderVerticalBarOnDate(dayjs(), { barWidth: 2, highlight: true })}

              {itemChildrenMap.get('root')?.map((item, i) => (
                <ItemBar key={item.id + '_bar'} item={item} isFirstBar={i === 0} {...ItemBarProps} />
              ))}
            </ScrollContainer>
          </div>
        </div>
      </ScrollContainer>

      {legend && (
        <div className="flex flex-wrap gap-x-6 items-center justify-center mt-4 text-gray-700 dark:text-gray-200">
          {legend.map((item) => (
            <div key={item.color} className="flex items-center text-sm">
              {item.label}
              <span className="h-3 w-3 block rounded ml-1" style={{ backgroundColor: item.color }} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Gantt

import React from "react";

const Pencil = ({ width = 13, height = 15, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0385742 11.8078L8.65396 3.19242L10.8078 5.34627L2.19242 13.9617H0.0385742V11.8078ZM9.37191 2.47447L10.8078 1.03857L12.9617 3.19242L11.525 4.62904L9.37191 2.47447Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Pencil;

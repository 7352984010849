import { useEffect, useState } from "react";

export function useTheme() {
  const [theme, setTheme] = useState(
    localStorage.theme === "dark" ||
      (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
      ? "dark"
      : "light"
  );

  const toggleTheme = () => {
    theme === "light" ? changeTheme("dark") : changeTheme("light");
  };

  const changeTheme = (theme) => {
    if (theme === "dark") {
      window.document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    } else {
      window.document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
      setTheme("light");
    }
  };

  useEffect(() => {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
      const newTheme = e.matches ? "dark" : "light";
      changeTheme(newTheme);
    });
  }, []);

  return { theme, toggleTheme, changeTheme };
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../../contexts/AuthContext";

const WithToken = ({ token }) => {
  const navigate = useNavigate();

  const { resetPassword } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const watchPassword = watch("password");

  const onSubmit = async (data) => {
    const { password } = data;
    const res = await resetPassword(token, password);

    if (res?.success) {
      reset();

      toast.success("Password Changed Successfully", {
        position: "bottom-center",
        autoClose: 2500,
      });

      setTimeout(() => {
        toast.success("Sending you to login page", {
          position: "bottom-center",
          autoClose: 2500,
        });
      }, 1000);

      setTimeout(() => {
        navigate("/login");
      }, 4500);
    } else {
      toast.error("Error - Try Again", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="px-1.2rem w-full">
      <form className="w-full max-w-screen-sm mx-auto mt-1.6rem" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-control">
          <label className="label">
            <span className="label-text dark:text-gray-200">New Password</span>
          </label>
          <input
            className="input input-bordered dark:bg-slate-600 dark:text-gray-200"
            type="password"
            {...register("password", { required: true, minLength: 6 })}
          />

          {errors.password?.type === "required" && (
            <p className="label text-red-500">This field is required</p>
          )}
          {errors.password?.type === "minLength" && (
            <p className="label text-red-500">Password should be at least 6 characters</p>
          )}
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text dark:text-gray-200">Confirm New Password</span>
          </label>
          <input
            className="input input-bordered dark:bg-slate-600 dark:text-gray-200"
            type="password"
            {...register("confirmPW", {
              required: true,
              minLength: 6,
              validate: (val) => val === watchPassword,
            })}
          />
          {errors.confirmPW?.type === "required" && (
            <p className="label text-red-500">This field is required</p>
          )}
          {errors.confirmPW?.type === "minLength" && (
            <p className="label text-red-500">Password should be at least 6 characters</p>
          )}
          {errors.confirmPW?.type === "validate" && (
            <p className="label text-red-500">Passwords don't match</p>
          )}
        </div>

        <button className="btn btn-primary w-full mt-0.8rem" type="submit">
          Submit
        </button>
      </form>

      <ToastContainer position="bottom-center" autoClose={3000} newestOnTop />
    </div>
  );
};

export default WithToken;

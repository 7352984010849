import React from "react";

const Calendar = ({ width = 21, height = 22, color = "currentColor" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 20.5H0V2.5H3V0.5H5V2.5H13V0.5H15V2.5H18V10.5H16V8.5H2V18.5H9V20.5ZM19.13 15.49L20.54 14.08L18.42 11.96L17.01 13.37L19.13 15.49ZM18.42 16.2L13.12 21.5H11V19.38L16.3 14.08L18.42 16.2Z"
        fill={color}
      />
    </svg>
  );
};

export default Calendar;

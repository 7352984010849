import { Link } from 'react-router-dom'
import * as dayjs from 'dayjs'

import { useAuth } from '../../../contexts/AuthContext'
// SVGs
import Garbage from '../../../SVG/Garbage'
import Plus from '../../../SVG/Plus'
import Eye from '../../../SVG/Eye'
import LinearIcon from '../../../SVG/LinearIcon'

import { ProjectStateColors, ProjectStateIcons } from '.'

export default function ProjectCard({ project, isEditing, hasJoinedProject, joinProject, leaveProject }) {
  const { isAdmin } = useAuth()

  return (
    <div
      className="
        card 
        border 
        border-dark
        bg-base-100 
        rounded-md
        flex 
        flex-col 
        justify-between 
        dark:bg-slate-700 
        dark:text-gray-200 
      "
    >
      <div className="p-5 flex flex-col flex-1">
        <div className="flex-1">
          <h3 className="card-title dark:text-white text-xl">
            <Link to={`/dashboard/projects/${project.id}`} className="hover:underline">
              {project.name}
            </Link>
          </h3>
        </div>

        <div className="text-sm mt-2">
          <div className="flex items-center justify-between my-2">
            <span>Status</span>
            <span className="capitalize flex items-center">
              <span className="mr-1" style={{ color: ProjectStateColors[project.state] }}>
                {ProjectStateIcons[project.state]}
              </span>
              {project.state}
            </span>
          </div>
          <div className="flex items-center justify-between my-2">
            <span>End Date</span>
            <span className="tracking-wide">
              {project.targetDate ? dayjs(project.targetDate).format('M/D/YY') : 'TBD'}
            </span>
          </div>

          {isAdmin && (
            <div className="flex items-center justify-between mt-2">
              <a href={project.url} rel="noreferrer" target="_blank">
                <span className="underline ">View on Linear</span>
              </a>
              <a
                href={project.url}
                rel="noreferrer"
                target="_blank"
                className="bg-gradient-to-b from-[#6069CA] to-[#2B359C] rounded-md p-2 block w-fit"
              >
                <LinearIcon width={16} height={16} />
              </a>
            </div>
          )}
        </div>
      </div>

      <div>
        {isEditing ? (
          <button
            onClick={() => (hasJoinedProject ? leaveProject(project) : joinProject(project))}
            className={`
              hover:text-black  
              block 
              w-full 
              h-full 
              text-center 
              py-3
              font-medium 
              rounded-b-md 
              dark:bg-slate-500 
              border-t 
              border-dark
              transition-all
              group 
              ${
                hasJoinedProject
                  ? 'bg-red-100 hover:!bg-red-400 dark:bg-red-200 dark:text-black'
                  : 'bg-emerald-50 hover:!bg-emerald-400 dark:bg-emerald-100 dark:text-black'
              }
              `}
          >
            {hasJoinedProject ? (
              <span className="relative">
                <span className="absolute text-black left-[-20px] top-0 opacity-0 group-hover:opacity-100 transition-all">
                  <Garbage />
                </span>
                Remove Project
              </span>
            ) : (
              <span className="relative">
                <span className="absolute text-black left-[-25px] -top-[3px] opacity-0 group-hover:opacity-100 transition-all">
                  <Plus />
                </span>
                Add Project
              </span>
            )}
          </button>
        ) : (
          <Link
            to={`/dashboard/projects/${project.id}`}
            className="
              bg-blue-50
              hover:bg-primary 
              hover:text-white 
              block 
              w-full 
              h-full 
              text-center 
              py-3
              font-medium 
              rounded-b-md 
              dark:bg-slate-600 
              border-t 
              border-dark
              dark:hover:bg-primary 
              transition-all
              group"
          >
            <span className="relative">
              <span className="absolute text-white left-[-25px] top-[2px] hidden group-hover:block">
                <Eye />
              </span>
              View Project
            </span>
          </Link>
        )}
      </div>
    </div>
  )
}

import { Link } from 'react-router-dom'
import { useTheme } from '../../hooks/useTheme'

export default function Home() {
  const { theme } = useTheme()

  return (
    <main className="w-full overflow-hidden bg-gradient-to-tl from-blue-100 via-blue-50 to-gray-100 dark:from-slate-900 dark:via-slate-800 dark:to-slate-700">
      <div className="flex flex-col-reverse lg:flex-row items-center mt-2rem lg:mt-0 lg:min-h-[80vh] max-w-screen-2xl mx-auto px-2rem">
        <div className="text-center lg:text-left mb-4rem lg:mb-0 flex-1 mt-6 lg:mt-0 text-gray-700 dark:text-gray-200">
          <h1 className="hidden lg:block text-[3.5rem] font-semibold tracking-wide border-b border-normal mb-2">
            Electriq <br />
            Web Dashboard
          </h1>

          <p className="lg:text-1.2rem max-w-md lg:max-w-[680px]">
            Welcome to the Electriq Web Dashboard, where you can get view, manage, & collaborate with our team on your
            active web projects!
          </p>
          <p className="lg:text-1.2rem max-w-md lg:max-w-[680px] mt-4">Login or click below to request access!</p>

          <div className="flex items-center gap-4 mt-8 w-full max-w-[450px] mx-auto lg:mx-0">
            <Link to="/login" className="white-btn py-3 block w-full">
              Login
            </Link>
            <a
              href="mailto:taylor@electriqmarketing.com?subject=Request Access to Electriq Web Dashboard"
              rel="noreferrer"
              target="_blank"
              className="white-btn py-3 block w-full"
            >
              Get Access
            </a>
          </div>
        </div>

        <div className="rounded-md border border-dark h-fit overflow-hidden mx-auto pointer-events-none shadow-xl lg:ml-4rem flex-[1.5] animate-fade-in-up">
          <div className="bg-white w-full h-4 flex justify-end items-center px-4 dark:bg-slate-600">
            <span className="bg-gray-300 h-2 w-2 rounded-full mr-3 dark:bg-gray-700" />
            <span className="bg-gray-300 h-2 w-2 rounded-full mr-3 dark:bg-gray-700" />
            <span className="bg-electriq-blue h-2 w-2 rounded-full dark:bg-slate-500" />
          </div>

          <img
            src={theme === 'dark' ? '/images/dark-dashboard.png' : '/images/light-dashboard.png'}
            alt="Screenshot of the Web Dashboard interface showing a list of projects."
            className="aspect-video"
          />
        </div>

        <h1 className="lg:hidden text-center mb-4 text-2.6rem font-semibold text-transparent text-gray-600 dark:text-gray-200 tracking-wide">
          Electriq <br />
          Web Dashboard
        </h1>
      </div>
    </main>
  )
}

import React from "react";

const LinearIcon = ({ height = 150, width = 150, color = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M123.974 131.918C125.354 130.725 126.7 129.473 128.011 128.163C157.33 98.8438 157.33 51.3083 128.011 21.9893C98.6917 -7.32975 51.1562 -7.32975 21.8372 21.9893C20.5268 23.2997 19.275 24.6464 18.0817 26.0263L123.974 131.918Z"
        fill={color}
      />
      <path
        d="M115.031 138.561L11.4394 34.9691C9.36173 38.2511 7.56337 41.6591 6.04431 45.1591L104.841 143.956C108.341 142.437 111.749 140.638 115.031 138.561Z"
        fill={color}
      />
      <path
        d="M93.2043 147.904L2.09574 56.7957C0.986981 61.2308 0.2884 65.7473 0 70.2851L79.715 150C84.2527 149.712 88.7693 149.013 93.2043 147.904Z"
        fill={color}
      />
      <path
        d="M63.3969 149.267L0.732938 86.6031C3.08389 101.816 10.1186 116.444 21.8372 128.163C33.5557 139.881 48.1844 146.916 63.3969 149.267Z"
        fill={color}
      />
    </svg>
  );
};

export default LinearIcon;

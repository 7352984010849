import Kanban from './Kanban'
import Calendar from './Calendar'
import ProjectGantt from './ProjectGantt'

import * as dayjs from 'dayjs'
import * as minMax from 'dayjs/plugin/minMax'
dayjs.extend(minMax)

export default function Board({ boardView, project }) {
  return (
    <>
      {boardView === 'Kanban' && <Kanban project={project} />}
      {boardView === 'Calendar' && <Calendar project={project} />}
      {boardView === 'Timeline' && <ProjectGantt project={project} />}
    </>
  )
}

import React from "react";
import { Link } from "react-router-dom";
import Plus from "../../../SVG/Plus";

const AdminIssueButton = () => {
  return (
    <Link
      to="/dashboard/create-issue"
      className="
        fixed 
        bottom-[1rem]
        right-[1rem]
        z-[9999] 
        flex 
        items-center 
        py-3
        px-4
        text-white 
        shadow-md 
        border
        border-dark
        bg-black 
        rounded-full 
        cursor-pointer
        hover:!bg-white
        hover:text-black 
        active:!bg-primary
        active:text-white
        transition-all
        dark:bg-slate-700
      "
    >
      <span className="mr-2">Create issue</span>
      <Plus />
    </Link>
  );
};

export default AdminIssueButton;

import React from 'react'

const Canceled = ({ height = 20, color = 'currentColor' }) => {
  return (
    <svg
      height={height}
      width={height}
      className="bg-white rounded-full border-[1px] scale-110"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
        fill={color}
      />
    </svg>
  )
}

export default Canceled

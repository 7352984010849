import CreateUserForm from "./CreateUserForm";

export default function CreateUser() {
  return (
    <div className="max-w-screen-2xl w-full mx-auto px-1.2rem lg:px-2rem pb-2.4rem">
      <h1 className="py-5 font-semibold text-4xl dark:text-gray-200">Create User</h1>
      <CreateUserForm />
    </div>
  );
}
